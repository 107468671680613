$assetsPath: '//v251.static.core-test.switchfly.com/static/11483d3/resources/shopping/images/themes/jetblue';

// Imports and mixins
// -------------------------

@import "_webfonts";

// $screen-sm to $screen-md
$site-header-brand-container-col-width: 4;
$site-header-loyalty-container-col-width: 8;

// Handheld (<480px)
$site-header-brand-container-col-width-handheld: 4;
$site-header-loyalty-container-col-width-handheld: 8;

// Client colors
// -------------------------
$med-blue: #0284d4;
$teal: #03b5e2;
$blue: #306faa;

// Brand colors
// -------------------------

$brand-primary: #00205B;
$brand-featured: #0133A0;
$brand-special: #FF8200;
$brand-info: #007A7A;
$brand-success: #0133A0;
$brand-active: #99CCFF;
$brand-danger: #CC0F0F;
$brand-warning: #A36200;
$brand-alternate: #999;

$almost-white: #F6F6F6;
$gray-lighter: #D3D3D3;
$gray-light: #999;
$gray-medium: #757575;
$gray-cool: #63666A;
$gray: #63666A;
$gray-dark: #303234;

// Typography
// -------------------------

$font-family-sans-serif: "Klarheit", Helvetica, Arial, sans-serif;
$font-family-sans-serif-bold: "Klarheit", Helvetica, Arial, sans-serif;
$headings-text-color-h1: $brand-primary;
$headings-text-color-h2: $brand-primary;
$headings-font-weight: 400;
$headings-font-weight-h2: 500;

$link-color: $brand-primary;
$link-hover-color: $brand-featured;
$text-strong-font-weight: 500;
$pricing-summary-font-weight: 500; //setting font weight back to bold since h2 is normal

// Helper classes
// -------------------------
$border-radius-alternate: 6px;
$strikethrough-text-color: $gray-dark;
$points-text-color: $brand-primary;

$input-border: #d4d4d4;
$input-border-radius: $border-radius-alternate;
$input-box-shadow: none;

//Buttons
//--------------------------
$btn-font-family: $font-family-sans-serif-bold;
$btn-border-radius: 6px;
$btn-font-weight: 500;
$btn-letter-spacing: 0;

//Primary
$btn-primary-bg: $brand-featured;
$btn-primary-border: $btn-primary-bg;
$btn-primary-bg-color-hover: $brand-primary;
$btn-primary-border-hover: $btn-primary-bg-color-hover;

//Secondary
$btn-secondary-color: #fff;
$btn-secondary-bg: #63666A;
$btn-secondary-border: #63666A;

$btn-secondary-color-hover: #fff;
$btn-secondary-bg-color-hover: #303234;
$btn-secondary-border-hover: #303234;

//Default
$btn-default-color: $brand-featured;
$btn-default-bg: #fff;
$btn-default-border: $brand-featured;

$btn-default-color-hover: #fff;
$btn-default-bg-color-hover: $brand-primary;
$btn-default-border-hover: $brand-primary;

// Disabled (all variants)
$btn-disabled-color: $gray-dark;
$btn-disabled-bg-color: $gray-light;

// Site Header & Footer
//--------------------------
$site-header-background: #fff;
$site-header-text-color: $brand-featured;
$header-link-color: $brand-featured;
$site-footer-background-color: $brand-primary;
$site-footer-link-color: #fff;
$site-footer-link-hover-color: #fff;

//Deals
//--------------------------
$product-deal-bg-color: $brand-primary;
$promotion-notation-text-color: #fff;

$rate-deal-bg-color: #D2E7F5;
$rate-deal-text-color: #334D7C;
$rate-deal-margins: 0 0 10px;
$rate-deal-border-radius: $border-radius-alternate;
$rate-deal-max-width_handheld: fit-content;
$rate-deal-font-size_handheld: 14px;
$rate-deal-line-height_handheld: 1.6;
$product-deal-font-weight: 500;
$product-deal-border-radius: $border-radius-alternate 1px;
$product-deal-font-size_base: 16px;

$product-deal-exclusive-bg-color: #334D7C;
$product-deal-exclusive-bg-color_handheld: #334D7C;
$product-deal-exclusive-text-color: #fff;

$product-deal-client-approved-bg-color: #334D7C;
$product-deal-client-approved-text-color: #fff;
$product-deal-client-approved-bg-color_handheld: $product-deal-client-approved-bg-color;
$product-deal-client-approved-text-color_handheld: $product-deal-client-approved-text-color;

$promotion-notation-bg-color: #0072ce;
$promotion-notation-border-radius: $border-radius-alternate;
$promotion-notation-padding: 5px 10px 4px;
$promotion-notation-font-weight: 500;

//Product Cards
//--------------------------
$star-rating-active-text-color: #38c2e8;
$list-icon-bullet-color: $brand-primary;
$price-text-color: $brand-primary;
$product-price-font-weight: 600;
$product-price-total-color: $brand-primary;
$price-font-family: $font-family-sans-serif-bold;
$product-body-bg-color-loading: #fff;
$product-border-color-loading: $brand-success;
$product-border-width-loading: 3px;
$product-border-color-selected: $brand-featured;
$product-body-bg-color-xsell: $almost-white;
$product-body-border-color-xsell: $gray-lighter;
$product-selected-price-container-bg-color: #E8F4FA;

//Slider Filter
//--------------------------
$filter-slider-selection-background-color: #CCD2DE;
$filter-slider-handle-background-color: #418BD8;
$filter-slider-handle-border-color: $filter-slider-handle-background-color;
$filter-slider-handle-border-width: 0;

// Page Aside Contents Container
// -------------------------
$aside-contents-container-background-color: #fff;
$aside-contents-container-border-color: transparent;
$aside-contents-section-background-color: #fff;
$aside-contents-section-border-color: #fff;
$aside-contents-section-box-shadow: none;

$checkout-aside-section-body-background-color: $almost-white;
$checkout-aside-section-header-background-color: $checkout-aside-section-body-background-color;
$checkout-aside-section-header-border-color: #d3d3d3;

// Alerts
//--------------------------

$alert-padding: 10px;
$alert-border-radius: $border-radius-alternate;
$alert-link-font-weight: normal;

$alert-default-bg: #fff;
$alert-default-text: #000;
$alert-default-border: transparent;

$alert-info-bg: #ECF8FF;
$alert-info-text: $brand-info;
$alert-info-border: transparent;

$alert-warning-bg: #FFF4EA;
$alert-warning-text: $brand-warning;
$alert-warning-border: transparent;

$alert-danger-bg: #FFE3E3;
$alert-danger-text: $brand-danger;
$alert-danger-border: transparent;

$alert-success-text: $gray-dark;
$alert-success-border: transparent;

$alert-margin-top: 3px;

// Page Level Styles
//--------------------------
$section-header-text-color: $brand-primary;
$section-header-border-bottom-color: #d3d3d3;
$filter-section-divider-color: #d3d3d3;

$hotel-view-toggle-border-radius: 10px;
$toolbar-border-radius: 10px;
$image-btn-border-radius: 10px;
$trip-summary-product-border-radius: 10px;

$media-heading-font-weight: 400;
$media-heading-link-color: $brand-primary;

$dashcart-product-header-background-color-xsell: $blue;

$badge-bg: $almost-white;

$tag-padding-base: 2px 5px 0;
$tag-border-radius: 2px;
$tag-success-bg: #D2E7F5;
$tag-info-bg: #D2E7F5;
$tag-font-weight: normal;
$tag-large-border-radius: $border-radius-alternate;
$product-notice-line-height: 1;

$nav-tabs-link-color: $gray-dark;
$nav-pills-alt-hover-font-color: #fff;
$nav-pills-alt-hover-bg-color: $brand-featured;
$line-height-computed: 21px;

//Custom
//---------------------------------------

.pagination > li > a,
.inline-airport-code strong,
#site-footer a {
  font-weight: 400;
}

#confirmation-page-container .activity .btn {
  margin-bottom: calc(#{$line-height-computed} / 2);
}

.btn-text-input {
  font-weight: 400 !important;
}

.btn.btn-link,
.btn-block.btn-link {
  font-weight: 500;
}

.toolbar.toolbar-dynamic .toolbar-items > li.active > a {
  color: $gray-dark;
}

.nav-tabs > li.active {
  > a,
  > a:hover,
  > a:focus {
    box-shadow: inset 0 -3px 0 0 $gray-dark !important;
  }
}

.row.header-toolbar {
  margin: 0 auto;
}

.jtp-icon {
  fill: $site-header-text-color;
  vertical-align: middle;

  span {
    vertical-align: middle;
  }
}

.header-loyalty-container {
  color: $header-link-color;
  float: right !important;

  .item {
    margin-top: 6px;
    display: inline-block;
  }

  div:last-of-type {
    float: right; //hack to align login button on same line
  }

  a.item {
    color: $header-link-color;
    margin-right: 20px;
    font-family: $font-family-sans-serif;

    &:hover {
      text-decoration: none;
    }

    span {
      font-size: 16px;
      vertical-align: middle;
      font-weight: 400;
    }
  }

  .loyalty-summary {
    font-size: 16px;
    font-weight: 400;
    color: $header-link-color;

    a.link,
    .btn-group .btn {
      font-weight: 400;
      font-sizes: 16px;
    }

    .btn-default {
      @extend .btn-link;
      color: $header-link-color !important;
      line-height: 1;
      display: inline-flex;
      align-items: center;

      &:hover {
        text-decoration: none !important;
      }

      &::before {
        font-family: "switchflyShopping";
        content: '\e95b';
        font-size: 22px;
      }
    }
  }
}

.loyalty-summary {
  margin-top: 6px;
}

.product {
  .product-deal-container div.product-deal,
  div.product-deal.rate-deal {
    padding: 0 10px;

    &::after {
      content: none !important;
    }
  }

  div.product-deal.rate-deal { //Primary Message = Promo flag, price container
    padding-top: 4px; // makes min-height: 28px
    padding-bottom: 3px;

    &::before {
      content: none !important;
    }
  }

  .product-deal-container div.product-deal { // JetBlue Approved / Exclusive Deal
    padding-top: 4px; // makes min-height: 24px
    padding-bottom: 2px;
  }
}

.case-base {
  .toggle-flight-details {
    padding-left: 8.33333%; //equivalent of col-xs-1

    &.no-filler {//TODO SS-13603: remove filler class when selected air card journey is integrated
      padding-left: 0;
    }

    .icon-container {
      float: right;
      margin-top: -2px;
    }
  }
}

//-- temporarily hides airplane icon in car results--
.products-cars {
  .product-body {
    .icon.icon-product__flight {
      display: none;
    }
  }
}

.case-handheld {
  .products-cars {
    .address .icon.icon-product__flight {
      display: none;
    }
  }
}
//-----------------------------------------------------

.map-view-toggle {
  .btn-default {
    @extend .btn-primary;
  }
}

div.filters-container .filter h3 {
  margin: 0 0 20px;
}

.tag.tag-success {
  +.text-success {
    color: $gray-dark;
  }
}

.tag.tag-info {
  +.text-info {
    color: $gray-dark;
  }
}

@media (max-width: 769px) {
  .header-loyalty-container {
    .item span,
    .loyalty-summary .btn-group span {
      display: none;
    }
  }
}

.case-handheld {
  .btn:not(.btn-link),
  .form-control {
    padding-top: 10px; //fixes vertical placement of client font
  }

  .header-loyalty-container .block-on-xs {
    display: block;

    a.item {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}

.toggle-flight-details {
  padding-left: 8.33333%; //equivalent of col-xs-1
  .icon-container {
    float: right;
    margin-top: -2px;
  }
}

body .text-warning {
  color: $gray-dark;
}

// ICONS

$icon-alert__help: "\e93e";
$icon-alert__info: "\e920";

.icon.icon-collapsible--closed:before {
    content: "\e935";
}

.icon.icon-collapsible--opened {
  &:before {
    content: "\e935";
    @include transform(rotate(90deg));
  }
}

.icon-product-notice__enhanced-health-measures-badge {
  display: none !important;
}

.cobrand-crewmember {
  .header-loyalty-container {
    display: none;
  }

  .ffd-logo {
    height: 50px;
  }
}

.cobrand-default,
.cobrand-Redeem {
  .automation-selectedButton {
    background-color: $brand-featured !important;
    border-color: $brand-featured !important;

    &:hover {
      background-color: $brand-primary !important;
      border-color: $brand-primary !important;
    }
  }

  .automation-refundableLabel,
  .automation-refundableUntilDateLabel {
    color: #2A7D09 !important;
  }

  .automation-nonRefundableLabel {
    color: #D50F0F !important;
  }
}

.cobrand-crewmember,
.cobrand-hotelcarflowtest {
  .automation-refundableLabel,
  .automation-refundableUntilDateLabel {
    color: #2A7D09 !important;
  }

  .automation-nonRefundableLabel {
    color: #D50F0F !important;
  }
}



// IMPORTANT: Must be loaded LAST

@import "_responsive";
