// This file is included in sass-bootstrap-shopping.scss
// ------------------------------------------------------------------------
// Global / Application (Switchfly application style) Variables
// WARNING: THIS IS A SHARED FILE FOR THE ENTIRE APPLICATION; tread lightly
// ------------------------------------------------------------------------

//
// Variables
// --------------------------------------------------

// Global values
// --------------------------------------------------


// Scaffolding
// -------------------------

$body-bg: #fff !default;
$text-color: $gray-dark !default;

// Links
// -------------------------

$link-color: $brand-primary !default; // There are overrides for these colors in $aside- section below
$link-hover-color: darken($link-color, 15%) !default;
$link-text-decoration: none !default;
$btn-link-text-decoration: $link-text-decoration !default;

// Typography
// -------------------------

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 14px !default;
$font-size-base_handheld: 16px !default; // This has to be at least 16px for iOS proper input zoom levels
$font-size-large: calc(#{$font-size-base} * 1.25) !default; // ~18px
$font-size-small: calc(#{$font-size-base} * 0.85) !default; // ~12px
$font-size-xs: calc(#{$font-size-base} * 0.70) !default; // ~10px
$font-size-small_handheld: calc($font-size-base_handheld * 0.85); // ~14px
$font-size-h1: calc(#{$font-size-base} * 2.75) !default; // ~38px
$font-size-h2: calc(#{$font-size-base} * 2) !default; // ~28px
$font-size-h3: calc(#{$font-size-base} * 1.43) !default; // ~20px
$font-size-h4: calc(#{$font-size-base} * 1.28) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: calc(#{$font-size-base} * 0.85) !default; // ~12px

$font-size-h1_handheld: calc(#{$font-size-base_handheld} * 1.71) !default; // ~27px
$font-size-h2_handheld: calc(#{$font-size-base_handheld} * 1.28) !default; // ~20px
$font-size-h3_handheld: calc(#{$font-size-base_handheld} * 1.14) !default; // ~18px
$font-size-h4_handheld: $font-size-base_handheld !default; // 16px
$font-size-large_handheld: calc(#{$font-size-base_handheld} * 1.14) !default; // ~18px
$font-size-xlarge_handheld: calc(#{$font-size-base_handheld} * 1.5) !default; // ~24px

$line-height-base: 1.5 !default;
//too many nested calcs that breaks the calculation for 1.0 clients
//$line-height-computed: calc(#{$font-size-base} * #{$line-height-base}) !default; // ~21px
$line-height-computed: 21px !default;
$line-height-computed-sm: 10px !default;

$h1-margin-vertical: $line-height-computed !default;
$h2-margin-vertical: $line-height-computed !default;
$h3-margin-vertical: $line-height-computed !default;
$h4-margin-vertical: ($line-height-computed / 2) !default;

$headings-font-family: $font-family-base !default;
$headings-line-height: 1.1 !default;
$headings-font-weight: 500 !default;
$headings-font-weight-h1: $headings-font-weight !default;
$headings-font-weight-h2: $headings-font-weight !default;
$headings-font-weight-h3: $headings-font-weight !default;
$headings-font-weight-h4: $headings-font-weight !default;
$headings-text-color: $text-color !default;
$headings-text-color-h1: $headings-text-color !default;
$headings-text-color-h2: $headings-text-color-h1 !default;

$media-heading-font-weight: bold !default;
$media-heading-link-color: inherit !default;

$price-font-family: $font-family-base !default;

$text-normal-font-weight: normal !default;
$text-medium-font-weight: 500 !default;
$text-strong-font-weight: bold !default;

// Components
// -------------------------
// Based on 14px font-size and 1.428 line-height (~20px to start)

$padding-small-vertical: 5px !default;
$padding-small-horizontal: 8px !default;

$padding-base-vertical: 6px !default;
$padding-base-horizontal: 6px !default;

$padding-large-vertical: 10px !default;
$padding-large-horizontal: 16px !default;

$padding-xlarge-vertical: 50px !default;

$padding-common-md: 20px !default;

$line-height-large: 1.33 !default;
$line-height-small: 1.5 !default;
$line-height-xs: 1.25 !default;

$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;
$border-radius-xl: 40px !default;
$base-border-color: $gray-lighter !default;
$darker-border-color: $gray-medium !default;

$component-active-bg: $brand-primary !default;

$caret-width-base: 4px !default;
$caret-width-large: 5px !default;

$page-area-padded: 5vw !default;
$page-area-padded-sm: $page-area-padded/2 !default;

$select-option-grid-gap: 40px !default;
$select-option-row-gap: $padding-common-md !default;

$divider-pipe-offset: 10px !default;

// Box Shadows
// Keeps shadows consistent, use these vars instead of creating one offs
// -------------------------
$shadow-sm: 0 1px 2px 1px rgba(0, 0, 0, 0.10) !default;
$shadow-md: 0 2px 6px 0 rgba(0, 0, 0, 0.14), 0 1px 2px 0 rgba(0, 0, 0, 0.08) !default;
$shadow-lg: 0 6px 14px 0 rgba(0, 0, 0, 0.14), 0 6px 6px 0 rgba(0, 0, 0, 0.10) !default;
$shadow-xl: 0 10px 20px 2px rgba(0, 0, 0, 0.17), 0 6px 6px 0 rgba(0, 0, 0, 0.14), 0 1px 2px 0 rgba(0, 0, 0, 0.08) !default;
$shadow-xxl: 0 19px 38px 0 rgba(0, 0, 0, 0.30), 0 15px 12px 0 rgba(0, 0, 0, 0.22) !default;
$shadow-inset: inset 0 1px 4px 0 rgba(0, 0, 0, 0.15) !default;
$shadow-hover-transition: all 0.3s cubic-bezier(.25, .8, .25, 1) !default;
$shadow-text: 0 2px 4px rgba(0, 0, 0, 0.95) !default;

// Tables
// -------------------------

$table-cell-padding: 8px !default;
$table-condensed-cell-padding: 5px !default;

$table-bg: $body-bg !default;
$table-bg-accent: $almost-white !default; // for tr striping
$table-bg-hover: #f5f5f5 !default;
$table-bg-active: $table-bg-hover !default;
$table-alternate-thead-bg-color: $gray-lighter !default;
$table-tbody-stripe-bg-color: $gray-lighter !default; // for striping of alternating tbody elements
$table-tbody-stripe-border-color: darken($gray-lighter, 5%) !default;
$table-border-color: $base-border-color !default; // table and cell border
$price-table-total-border-color: $base-border-color !default;
$price-table-total-border-color_handheld: $gray-light !default;
$price-table-total-border-style_handheld: dotted !default;
$price-table-border-radius: 0 !default;
$price-table-bg-color_handheld: $almost-white !default;
$price-table-border-color_handheld: $gray-lighter !default;
$price-table-padding_handheld: $padding-large-vertical $padding-large-horizontal !default;
$price-table-cell-padding_handheld: 0 $table-condensed-cell-padding !default;
$product-summary-page-body-bg-color_handheld: $almost-white !default;
$product-summary-price-table-bg-color_handheld: $product-summary-page-body-bg-color_handheld !default;
$product-summary-price-table-text-color_handheld: $gray-dark !default;

// Buttons
// *-bg-start* and *-bg-end* are set in app-mixins and used with gradient-buttons
// *-hover applies to hover/active/focus
// -------------------------

$btn-font-size: $font-size-base !default;
$btn-font-size_handheld: $font-size-base_handheld !default;
$btn-link-font-size: $font-size-base !default; // TODO: Consider 1em here instead
$btn-link-font-size_handheld: $font-size-base_handheld !default; // TODO: Consider 1em here instead
$btn-font-weight: normal !default;
$btn-letter-spacing: .5px !default;
$btn-font-family: $font-family-base !default;

$btn-border-radius: $border-radius-base !default;
$btn-border-radius-sm: $border-radius-small !default;
$btn-border-width: 1px !default;

$btn-padding-vertical: 6px !default;
$btn-padding-horizontal: 15px !default;
$btn-xs-padding: 1px 5px !default;
$btn-lg-padding-vertical: 10px !default;
$btn-min-width: 10em !default;
$btn-xl-padding-horizontal: $btn-padding-horizontal*6 !default;
$btn-xl-font-size: $font-size-large !default;
$btn-xl-border-radius: $border-radius-large !default;
$btn-sm-border-radius: $border-radius-small !default;
$btn-lg-border-radius: $border-radius-large !default;

// Primary
$btn-primary-color: #fff !default;
$btn-primary-bg: $link-color !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;

$btn-primary-color-hover: $btn-primary-color !default;
$btn-primary-bg-color-hover: darken($btn-primary-bg, 12%) !default;
$btn-primary-border-hover: darken($btn-primary-border, 12%) !default;

$btn-primary-color-active: $btn-primary-color-hover !default;
$btn-primary-bg-color-active: $btn-primary-bg-color-hover !default;
$btn-primary-border-active: $btn-primary-border-hover !default;

// Secondary
$btn-secondary-color: #fff !default;
$btn-secondary-bg: $gray !default;
$btn-secondary-border: darken($btn-secondary-bg, 5%) !default;

$btn-secondary-color-hover: $btn-secondary-color !default;
$btn-secondary-bg-color-hover: darken($btn-secondary-bg, 8%) !default;
$btn-secondary-border-hover: darken($btn-secondary-border, 12%) !default;

$btn-secondary-color-active: $btn-secondary-color-hover !default;
$btn-secondary-bg-color-active: $btn-secondary-bg-color-hover !default;
$btn-secondary-border-active: $btn-secondary-border-hover !default;

// btn-switch (boolean / toggle on/off buttons)
$btn-switch-color: $gray !default;
$btn-switch-bg: $almost-white !default;
$btn-switch-border: $gray-light !default;

$btn-switch-color-hover: $gray !default;
$btn-switch-bg-color-hover: $gray-lighter !default;
$btn-switch-border-hover: $gray !default;

$btn-switch-color-active: $gray !default;
$btn-switch-bg-color-active: #fff !default;
$btn-switch-border-active: $gray !default;

// Default
$btn-default-color: $link-color !default;
$btn-default-bg: #fff !default;
$btn-default-border: $link-color !default;

$btn-default-color-hover: $btn-primary-color !default;
$btn-default-bg-color-hover: $btn-primary-bg !default;
$btn-default-border-hover: $btn-primary-border !default;

$btn-default-color-active: $btn-default-color-hover !default;
$btn-default-bg-color-active: $btn-default-bg-color-hover !default;
$btn-default-border-active: $btn-default-border-hover !default;

// toggle
$btn-toggle-color: $btn-default-color !default;
$btn-toggle-bg: $btn-default-bg !default;
$btn-toggle-border: $btn-default-border !default;
$btn-toggle-color-hover: $btn-default-color-hover !default;
$btn-toggle-bg-color-hover: $btn-default-bg-color-hover !default;
$btn-toggle-border-hover: $btn-default-border-hover !default;
$btn-toggle-color-active: $btn-default-color-active !default;
$btn-toggle-bg-color-active: $btn-default-bg-color-active !default;
$btn-toggle-border-active: $btn-default-border-active !default;

// Search Form Pills
$btn-search-pill-font-size: $btn-font-size !default;
$btn-search-pill-color: $link-color !default;
$btn-search-pill-bg: #fff !default;
$btn-search-pill-border: $link-color !default;
$btn-search-pill-border-radius: $btn-border-radius !default;

$btn-search-pill-color-hover: $btn-primary-color !default;
$btn-search-pill-bg-color-hover: $btn-primary-bg !default;
$btn-search-pill-border-hover: $btn-primary-border !default;

$btn-search-pill-color-active: $btn-default-color-hover !default;
$btn-search-pill-bg-color-active: $btn-default-bg-color-hover !default;
$btn-search-pill-border-active: $btn-default-border-hover !default;
$btn-search-pill-padding: $btn-padding-vertical $btn-padding-horizontal !default;
$btn-search-pill-border-inactive: 1px solid $btn-search-pill-border-active !default;
$btn-search-pill-inactive-color: $btn-search-pill-color !default;

// Disabled (all variants)
$btn-disabled-color: $gray !default;
$btn-disabled-bg-color: $gray-light !default;

//Link
$btn-link-border-width: 0 !default;
$btn-link-border-color: transparent !default;

//Button Image
$btn-image-label-opacity: rgba(0, 0, 0, .60) !default;

// Points Toggle
$arrow-down-color: $btn-default-color !default;

// Forms
// -------------------------

$legend-alt-bg-color: $gray-lighter !default;
$legend-alt-text-color: $text-color !default;

$input-bg: #fff !default;
$input-bg-disabled: $gray-lighter !default;
$input-bg-color-alt: $input-bg !default;

$input-color: $gray !default;
$input-border: $gray-light !default;
$input-border-radius: $border-radius-small !default;
$input-border-focus: $link-color !default;

$input-color-placeholder: $gray !default;
$input-placeholder-style: normal !default;

$input-box-shadow: $shadow-inset !default;
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
$input-height-large: calc(18px * #{$line-height-large} + #{$padding-large-vertical} * 2 + 2px) !default;
$input-height-small: calc(12px * #{$line-height-small} + #{$padding-small-vertical} * 2 + 2px) !default;

$input-validation-error-background-color: inherit !default;
$input-validation-error-text-color: $brand-danger !default;
$input-validation-border-radius: $input-border-radius $input-border-radius 0 0 !default;

$input-group-addon-bg: $gray-lighter !default;
$input-group-addon-border-color: $input-border !default;

$select-info-bg-color: $brand-active !default;
$select-info-text-color: $link-color !default;
$select-info-border-color: $input-border !default;

$form-label-font-weight: normal !default;
$form-validation-text-font-weight: $form-label-font-weight !default;

// COMPONENT VARIABLES
// --------------------------------------------------

// Grid system
// --------------------------------------------------

$grid-columns: 12 !default; // Number of columns in the grid system
$grid-gutter-width: 16px !default; // Padding, to be divided by two and applied to the left and right of all columns
$grid-float-breakpoint: $screen-sm !default; // Point at which the navbar stops collapsing

// Grid Info - lays out label + data columns that stack on handheld
// -------------------------
$grid-info-gap: 10px !default;

// Flexbox
// --------------------------------------------------
$flex-gap: $padding-large-horizontal !default;

// STACK
// Adding .stack to a container will offset each *immediate* child from it's proceeding sibling
// -------------------------
$stack-offset-space: $padding-base-vertical !default;
$stack-bordered-offset-space: 0 !default;
$stack-bordered-border-width: 1px !default;
$stack-bordered-border-color: $base-border-color !default;
$stack-list-styled-box-shadow: $shadow-sm !default;

// Navbar
// -------------------------

// Basics of a navbar
$navbar-height: 50px !default;
$navbar-margin-bottom: 0 !default;

$navbar-default-color: $text-color !default;
$navbar-default-bg: transparent !default;
$navbar-default-border: transparent !default;

$navbar-border-radius: 0 !default;
$navbar-padding-horizontal: floor($grid-gutter-width / 2) !default;
$navbar-padding-vertical: (($navbar-height - $line-height-computed) / 2) !default;

// Navbar links
$navbar-default-link-color: $text-color !default;
$navbar-default-link-color_handheld: $navbar-default-link-color !default;
$navbar-default-link-hover-color: $text-color !default;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: $link-color !default;
$navbar-default-link-active-bg: transparent !default;
$navbar-default-link-disabled-color: $gray !default;
$navbar-default-link-disabled-bg: transparent !default;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color !default;
$navbar-default-brand-hover-color: darken($navbar-default-link-color, 10%) !default;
$navbar-default-brand-hover-bg: transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg: $gray-medium !default;
$navbar-default-toggle-icon-bar-bg: $gray-lighter !default;
$navbar-default-toggle-border-color: $darker-border-color !default;
$navbar-default-toggle-color: $navbar-default-toggle-border-color !default;
$navbar-default-toggle-hover-color: dynamicColor($navbar-default-toggle-hover-bg) !default;

// Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: $gray-light !default;
$navbar-inverse-bg: #222 !default;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%) !default;

// Inverted navbar links
$navbar-inverse-link-color: $gray-light !default;
$navbar-inverse-link-hover-color: #fff !default;
$navbar-inverse-link-hover-bg: transparent !default;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-link-disabled-color: #444 !default;
$navbar-inverse-link-disabled-bg: transparent !default;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color: #fff !default;
$navbar-inverse-brand-hover-bg: transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333 !default;
$navbar-inverse-toggle-icon-bar-bg: #fff !default;
$navbar-inverse-toggle-border-color: #333 !default;

// Checkbox Toggle
$checkbox-toggle-handle-bg-color-disabled: #fff !default;
$checkbox-toggle-handle-bg-color-active: #fff !default;

$checkbox-toggle-bg-color-disabled: $gray-light !default;
$checkbox-toggle-bg-color-active: $brand-primary !default;
$checkbox-toggle-bg-color-hover: $gray-medium !default;

$checkbox-toggle-border-color-disabled: $gray-light !default;
$checkbox-toggle-border-color-active: $brand-primary !default;
$checkbox-toggle-border-color-hover: $gray-medium !default;

// Navs
// -------------------------
$nav-link-padding: $padding-large-vertical $padding-large-horizontal !default;
$nav-link-hover-bg: $gray-lighter !default;
$nav-pills-link-color: $link-color !default;
$nav-pills-link-font-weight: normal !default;
$nav-pills-link-active-font-weight: bold !default;

$nav-disabled-link-color: $gray-light !default;
$nav-disabled-link-hover-color: $gray-light !default;

$nav-open-link-hover-color: #fff !default;
$nav-open-caret-border-color: #fff !default;

// Tabs
$nav-tabs-border-color: $gray-lighter !default;
$nav-tabs-border-radius: $border-radius-base $border-radius-base 0 0 !default;

$nav-tabs-link-hover-border-color: $gray-lighter !default;

$nav-tabs-active-link-bg-color: $body-bg !default;
$nav-tabs-active-link-hover-bg: $body-bg !default;
$nav-tabs-active-link-hover-border-color: $link-color !default; // This is really an inset box shadow
$nav-tabs-active-link-hover-border: inset 0 -3px 0 0 $nav-tabs-active-link-hover-border-color !default;
$nav-tabs-link-color: $link-color !default;
$nav-tabs-bg-color-hover: transparent !default;
$nav-tabs-link-text-decoration: none !default;
$nav-tabs-active-link-hover-color: $nav-tabs-link-color !default;

$nav-tabs-justified-link-border-color: $darker-border-color !default;
$nav-tabs-justified-active-link-border-color: $body-bg !default;

// Pills
$nav-pills-padding-bottom: $padding-large-vertical * 2 !default;
$nav-pills-border-radius: $border-radius-large !default;
$nav-pills-border-style: inherit !default;
$nav-pills-active-link-hover-bg: $component-active-bg !default;
$nav-pills-active-link-hover-color: $link-color !default;
$nav-pills-active-font-color: #fff !default;
$nav-pills-hover-font-color: $link-color !default;
$nav-pills-hover-bg: transparent !default;
$nav-pills-hover-text-color: $nav-pills-active-link-hover-color !default;

$nav-pills-alt-padding-bottom: 0 !default;
$nav-pills-alt-border-radius: $border-radius-xl !default;
$nav-pills-alt-border-color: $base-border-color !default;
$nav-pills-alt-border-style: 1px solid $nav-pills-alt-border-color !default;
$nav-pills-alt-active-font-color: $nav-pills-active-font-color !default;
$nav-pills-alt-active-bg-color: $nav-pills-active-link-hover-color !default;
$nav-pills-alt-hover-font-color: $nav-pills-hover-font-color !default;
$nav-pills-alt-hover-bg-color: $nav-pills-hover-bg !default;
$nav-pills-alt-font-weight: $btn-font-weight !default;
$nav-pills-alt-active-font-weight: $btn-font-weight !default;
$nav-pills-alt-font-color: $nav-pills-hover-font-color !default;

// Pagination
// -------------------------

$pagination-bg: #fff !default;
$pagination-color: $link-color !default;
$pagination-size: initial !default;
$pagination-size_handheld: initial !default;
$pagination-item-border-radius: $pagination-size !default;
$pagination-text-align: initial !default;
$pagination-item-margin-right: initial !default;
$pagination-item-display: inline !default;
$pagination-border-radius-small: $border-radius-small !default;
$pagination-justify-content: right !default;

$pagination-border: $gray-lighter !default;
$pagination-outer-border-radius: $border-radius-base !default;

$pagination-hover-bg: $gray-lighter !default;
$pagination-hover-color: dynamicColor($pagination-hover-bg) !default;
$pagination-arrows-bg-hover-color: $pagination-hover-bg !default;
$pagination-arrows-border-hover-color: $pagination-hover-bg !default;

$pagination-active-bg: $brand-primary !default;
$pagination-active-color: #fff !default;

$pagination-disabled-color: #495057 !default;
$pagination-disabled-icon-color: inherit !default;

// Pager
// -------------------------

$pager-border-radius: 15px !default;
$pager-disabled-color: $gray-light !default;

// Form states and alerts
// -------------------------

$state-warning-text: $brand-warning !default;
$state-warning-bg: #fff4ea !default;
$state-warning-border: $brand-warning !default;

$state-danger-text: $brand-danger !default;
$state-danger-bg: #ffe3e3 !default;
$state-danger-border: $brand-danger !default;

$state-success-text: $brand-success !default;
$state-success-bg: #ebfbee !default;
$state-success-border: $brand-success !default;

$state-info-text: $brand-info !default;
$state-info-bg: #ecf8ff !default;
$state-info-border: $brand-info !default;

$state-pending-text: $brand-info !default;
// TODO: add background color and border to have all appropriate styles

$page-404-text: $state-warning-text !default;
$page-404-lead-text: $state-warning-text !default;
$page-404-paragraph-text: $state-warning-text !default;

// Tooltips
// -------------------------

$tooltip-max-width: 250px !default;
$tooltip-wide-max-width: 400px !default;
$tooltip-max-width_handheld: 200px !default;
$tooltip-color: #fff !default;
$tooltip-bg: #000 !default;
$tooltip-border-radius: 0 !default;

$tooltip-arrow-width: 5px !default;
$tooltip-arrow-color: $tooltip-bg !default;

$tooltip-font-size: $font-size-base !default;
$tooltip-line-height: $line-height-base !default;

// Popovers
// -------------------------
$popover-bg: #fff !default;
$popover-body-bg-alt: $almost-white !default;
$popover-min-width: 200px !default;
$popover-max-width: 300px !default;
$popover-md-min-width: 300px !default;
$popover-md-max-width: 400px !default;
$popover-border-color: $gray !default;
$popover-border-radius: 0 !default;
$popover-box-shadow: $shadow-lg !default;

$popover-body-spacing-v: 8px !default;
$popover-body-spacing-h: 14px !default;
$popover-body-width: auto !default;

$popover-title-bg: $gray !default;
$popover-title-text-color: #fff !default;
$popover-title-font-weight: inherit !default;
$popover-title-border: 1px solid $popover-border-color !default;

$popover-arrow-width: 10px !default;
$popover-arrow-color: $gray !default;

$popover-arrow-outer-width: ($popover-arrow-width + 1) !default;
$popover-arrow-outer-color: $popover-arrow-color !default;
$popover-arrow-outer-fallback-color: #999 !default;
$popover-footer-border-top: 1px solid $gray-lighter !default;

// Tags (forked from Bootstrap "labels")
// -------------------------

$tag-padding-base: 1px 5px !default;
$tag-padding-md: 3px 1em !default; // .tag-md
$tag-padding-lg: $padding-small-vertical $padding-small-horizontal !default; // .tag-lg
$tag-inline-offset-width: 5px !default;

$tag-default-bg: $almost-white !default;
$tag-default-text-color: dynamicColor($tag-default-bg) !default;

$tag-primary-bg: $brand-primary !default;
$tag-primary-text-color: dynamicColor($tag-primary-bg) !default;

$tag-success-bg: $brand-success !default;
$tag-success-text-color: dynamicColor($tag-success-bg) !default;
$tag-success-alt-text: $state-success-text !default;
$tag-success-alt-bg: $state-success-bg !default;

$tag-info-bg: $brand-info !default;
$tag-info-text-color: dynamicColor($tag-info-bg) !default;

$tag-warning-bg: $brand-warning !default;
$tag-warning-text-color: dynamicColor($tag-warning-bg) !default;

$tag-danger-bg: $brand-danger !default;
$tag-danger-text-color: dynamicColor($tag-danger-bg) !default;

$tag-special-bg-color: lighten($brand-special, 42%) !default;
$tag-special-text-color: dynamicColor($tag-special-bg-color) !default;
$tag-special-icon-color: $brand-special !default;

$tag-price-drop-bg: $tag-success-bg !default;
$tag-price-drop-color: dynamicColor($tag-success-bg) !default;

$tag-border-radius: 0 !default;
$tag-large-border-radius: 12px !default;

$tag-font-size: $font-size-small !default;
$tag-font-size-md: $font-size-base !default;
$tag-font-size-md_handheld: $font-size-base_handheld !default;
$tag-font-size-lg: $font-size-base !default;
$tag-font-weight: normal !default;
$tag-font-weight-md: 500 !default;

$tag-link-hover-color: #fff !default;

$tag-group-text-alignment_handheld: right !default;

$product-notice-line-height: 1.2 !default;

// Modals
// -------------------------
$modal-border-radius: 0 !default;
$modal-inner-padding: $padding-common-md !default;

$modal-content-bg: #fff !default;
$modal-content-box-shadow: $shadow-xxl !default;
$modal-backdrop-in-opacity: .65 !default;

$modal-backdrop-bg: #000 !default;
$modal-header-bg: $modal-content-bg !default;
$modal-header-text-color: dynamicColor($modal-header-bg) !default;
$modal-header-close-color: $modal-header-text-color !default;
$modal-header-close-opacity: 0.5 !default; //a11y - do not change
$modal-header-border-color: #e5e5e5 !default;

$modal-title-text-color: $modal-header-text-color !default;
$modal-title-padding: 15px !default;
$modal-title-line-height: $line-height-base !default;

$modal-body-bg: $modal-content-bg !default;
$modal-footer-bg: $modal-content-bg !default;
$modal-footer-border-color: $modal-header-border-color !default;
$zindex-modal-background: 99999;
$modal-footer-padding: ($modal-inner-padding - 1) $modal-inner-padding $modal-inner-padding !default;

$modal-alt-header-bg: $gray-dark !default;
$modal-alt-body-bg: $modal-alt-header-bg !default;
$modal-alt-content-bg: $modal-alt-header-bg !default;
$modal-alt-footer-bg: $modal-alt-header-bg !default;
$modal-alt-border-color: $gray-medium !default;
$modal-wide-max-width: 925px !default;

$modal-processing-text-font-size: $font-size-base !default;
$modal-processing-text-color: $text-color !default;
$modal-processing-text-font-weight: 500 !default;
$modal-width-lg: 600px !default;
$modal-width-md: 600px !default;
$modal-width-sm: 600px !default;
$modal-max-height: 85vh !default;
$modal-dialog-padding: 10px !default;
$modal-dialog-padding-vertical: 25px !default;
$modal-width-wide: 85vw !default;
$modal-xl-width: 90vw !default;

// Alerts
// Note: These should not reference the actual brand color vars because
// we need to be a bit more careful about color usage for a11y reasons.
// A theme can still set alert vars, but they must do so explicitly.
// -------------------------

$alert-padding: 15px !default;
$alert-border-radius: 0 !default;
$alert-link-font-weight: bold !default;
$alert-link-decoration: none !default;
$alert-link-decoration-hover: none !default;

$alert-default-bg: #fff !default;
$alert-default-text: $text-color !default;
$alert-default-border: $gray-lighter !default;

$alert-success-bg: $state-success-bg !default;
$alert-success-text: #396f3a !default; // app-theme $brand-success
$alert-success-border: #396f3a !default;
$alert-success-icon-color: inherit !default;

$alert-info-bg: $state-info-bg !default;
$alert-info-text: #0b7285 !default; // app-theme $brand-info
$alert-info-border: #0b7285 !default;
$alert-info-icon-color: inherit !default;

$alert-warning-bg: $state-warning-bg !default;
$alert-warning-text: #b24d04 !default; // app-theme $brand-warning
$alert-warning-border: #b24d04 !default;
$alert-warning-icon-color: inherit !default;

$alert-danger-bg: $state-danger-bg !default;
$alert-danger-text: #c92a2a !default; // app-theme $brand-danger
$alert-danger-border: #c92a2a !default;
$alert-danger-icon-color: inherit !default;

$alert-margin-top: initial !default;

// List group
// -------------------------
$list-group-bg: #fff !default;
$list-group-border: $gray-light !default;
$list-group-border-radius: 0 !default;

$list-group-hover-bg: $almost-white !default; // anchors
$list-group-active-color: $text-color !default;
$list-group-active-bg: $gray-lighter !default;
$list-group-active-border: $list-group-border !default;

$list-group-link-color: $link-color !default;
$list-group-link-heading-color: $text-color !default;
$list-group-border: 1px solid $gray-light !default;
$list-group-bg-color: #fff !default;

// Panels
// -------------------------
$panel-bg: #fff !default;
$panel-inner-border: $gray-medium !default;
$panel-border-radius: 0 !default;
$panel-footer-bg: #f5f5f5 !default;
$panel-default-icon-color: $text-color !default;
$panel-body-border-radius: 4px !default;
$panel-body-border: 1px solid $gray-light !default;
$panel-header-padding: $padding-base-vertical*2 $padding-small-horizontal*2 !default;

// Note: handheld panels have no left/right border

$panel-default-text: $gray-dark !default;
$panel-default-border: $gray-lighter !default;
$panel-default-heading-bg: #f5f5f5 !default;

$panel-primary-text: #fff !default;
$panel-primary-border: $brand-primary !default;
$panel-primary-heading-bg: $brand-primary !default;

$panel-success-text: $state-success-text !default;
$panel-success-border: $state-success-border !default;
$panel-success-heading-bg: $state-success-bg !default;

$panel-warning-text: $state-warning-text !default;
$panel-warning-border: $state-warning-border !default;
$panel-warning-heading-bg: $state-warning-bg !default;

$panel-danger-text: $state-danger-text !default;
$panel-danger-border: $state-danger-border !default;
$panel-danger-heading-bg: $state-danger-bg !default;

$panel-info-text: $state-info-text !default;
$panel-info-border: $state-info-border !default;
$panel-info-heading-bg: $state-info-bg !default;

$panel-price-body-top-font-family: $font-family-base !default;
$panel-price-body-top-color: $state-info-text !default;
$panel-price-body-bottom-font-family: $font-family-base !default;
$panel-price-amount-font-size: 30px !default;

// Panel-drawer
// -------------------------
$panel-drawer-header-bg-color: $almost-white !default;
$panel-drawer-header-hover-bg-color: #dee2e6 !default;
$panel-drawer-body-bg-color: $almost-white !default;

// Thumbnails
// -------------------------
$thumbnail-padding: 4px !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border: $gray-medium !default;
$thumbnail-border-radius: $border-radius-base !default;

$thumbnail-caption-color: $text-color !default;
$thumbnail-caption-padding: 9px !default;

// Wells
// -------------------------
$well-backround-color: $almost-white !default;
$well-border-color: transparent !default;
$well-border-radius: 0 !default;
$well-background-color-active: $state-info-bg !default;
$well-color-active: dynamicColor($well-background-color-active) !default;

// Toolbar
// -------------------------
$toolbar-border-radius: 0 !default;
$toolbar-dynamic-bg-color: $well-backround-color !default;
$toolbar-dynamic-bg-color-hover: $toolbar-dynamic-bg-color !default;

// Badges
// -------------------------
$badge-font-weight: bold !default;
$badge-line-height: 1 !default;

$badge-bg: $gray-lighter !default;
$badge-color: dynamicColor($badge-bg) !default;

$badge-font-size: $font-size-small;

$badge-border-radius: 10px !default;

$badge-padding-base: 3px 7px !default;

$badge-link-hover-color: #fff !default; // anchors only
$badge-active-color: $link-color !default; // anchors only
$badge-active-bg: #fff !default; // anchors only

// Carousel
// ------------------------
$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, .6) !default;

$carousel-control-color: #fff !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: .5 !default;
$carousel-control-font-size: $padding-common-md !default;

$carousel-indicator-active-bg: #fff !default;
$carousel-indicator-border-color: #fff !default;

$carousel-caption-color: #fff !default;

// Mosaic Images
// ------------------------
$images-mosaic-border-radius: 10px !default;
$product-images-mosaic-section-background: inherit !default;
$product-image-modal-caption-text-alignment_handheld: center !default;
$product-image-modal-caption-font-size_handheld: $font-size-base !default;
$product-image-modal-caption-background-opacity_handheld: rgba(0, 0, 0, .60) !default;
$product-image-modal-caption-text-color_handheld: #fff !default;
$product-image-arrow-color: $gray-dark !default;
$product-image-border-radius: 0 !default;
$product-image-thumbnail-border: 5px solid transparent !default;
$product-image-thumbnail-selected-border-color: $product-image-arrow-color !default;
// Close
// ------------------------
$close-color: #000 !default;
$close-font-weight: bold !default;
$close-text-shadow: 0 !default;

// Type
// ------------------------
$text-muted: $gray !default;
$text-disabled: $gray-medium !default;
$abbr-border-color: $gray !default;
$headings-small-color: $gray !default;
$blockquote-small-color: $gray !default;
$blockquote-border-color: $base-border-color !default;

// Marketing Kards
// -------------------------
$kard-border-width: 1px !default;
$kard-border-color: $gray-light !default;
$kard-body-bg-color: #fff !default;
$kard-alt-bg-color: $gray-dark !default;
$aside-kard-image-width: 25%;
$kard-border-radius: 0 !default;
$marketing-promotion-groups-bg-color: $almost-white !default;
$marketing-promotion-groups-bg-color_handheld: $marketing-promotion-groups-bg-color !default;
$kard-deck-background-color: transparent !default;
$kard-title-text-color: inherit !default;
$kard-title-font-size: $font-size-large !default;
$kard-title-font-weight: $headings-font-weight !default;
$kard-title-line-height: $line-height-base !default;
$kard-alt-title-text-color: inherit !default;
$kard-hero-message-container-padding: $padding-large-vertical $padding-large-horizontal !default;
$kard-link-box-hover-hover: $shadow-xl !default;
$search-form-marketing-kard-title-font-size: $font-size-h2 !default;
$search-form-marketing-kard-padding: 32px 24px !default;
$search-form-marketing-kard-background-color: $kard-body-bg-color !default;
$search-form-marketing-kard-background-color_handheld: $search-form-marketing-kard-background-color !default;
$search-form-marketing-kard-text-color: inherit !default;
$search-form-marketing-kard-text-color_handheld: $search-form-marketing-kard-text-color !default;

// Miscellaneous
// -------------------------

// Hr border color
$hr-border: $base-border-color !default;

//Hr border style
$hr-border-style: dashed !default;

// Horizontal forms & lists
$component-offset-horizontal: 180px !default;

// Container sizes
// --------------------------------------------------

// Small screen / tablet
$container-tablet: ((752px + $grid-gutter-width)) !default;

// Medium screen / desktop
$container-desktop: 1264px !default;

// Large screen / wide desktop
$container-lg-desktop: 1400px !default;

// ******************************************
// APPLICATION VARIABLES
// ******************************************

// Structural Elements
// -------------------------
$iframed-content-border-color: $base-border-color !default; // NOTE: This is actually being added as an outline
$iframed-content-border-width: 1px !default;

// Helper classes
// -------------------------
$price-text-color: $text-color !default;
$or-text-color: $alert-warning-text !default; // For the string 'or' that generally shows up with price elements, e.g. $1,000 or 100 points + $50
$or-text-font-weight: 700 !default;
$points-text-color: $brand-success !default;
$plus-text-color: $price-text-color !default;
$strikethrough-text-color: #bd362f !default;
$strikethrough-text-decoration-color: inherit !default;
$price-message-text-color: $text-color !default;
$price-message-promotion-text-color: darken($brand-success, 15%) !default;
$discount-price-text-color: $price-text-color !default;
$discount-points-text-color: $price-text-color !default;
$discount-points-text-color: $price-text-color !default;
$removable-text-color: $alert-danger-text !default; // Can apply to text as well as links
$removable-link-color: darken($removable-text-color, 5%) !default;
$removable-link-hover-color: darken($removable-text-color, 15%) !default; // Applies only to links
$light-alert-text-color: $alert-warning-text !default;

// --------------------------------------------------
// Page Level Styles

// top-elements mean the first children of .page-content; i.e. page-header, page-body, and page-footer
$page-top-elements-background-color: transparent !default;
$page-header-background-color: $page-top-elements-background-color !default;
$page-header-border-color: $base-border-color !default;
$page-header-bg-color_handheld: $almost-white !default;
$page-header-hero-background-color: $brand-primary !default;
$page-header-hero-text-color: dynamicColor($page-header-hero-background-color) !default;
$page-subheader-text-color: #000 !default;
$page-body-background-color: $page-top-elements-background-color !default;
$page-body-margin-bottom: 50px !default;
$page-body-margin-bottom_handheld: $padding-common-md !default;
$dashboard-search-form-min-height_handheld: 300px !default;
$width-full-page-bg-color: $almost-white !default;

$subheader-toolbar-row-background-color_handheld: $page-header-bg-color_handheld !default;
$subheader-toolbar-row-2-background-color_handheld: $subheader-toolbar-row-background-color_handheld !default;
$subheader-toolbar-row-text-color_handheld: dynamicColor($subheader-toolbar-row-background-color_handheld) !default;
$subheader-toolbar-row-anchor-color_handheld: $link-color !default;

$section-header-text-color: $text-color !default;
$section-header-border-bottom-width: 1px !default;
$section-header-border-bottom-color: $gray !default;

$section-header-background-color_handheld: $gray-lighter !default;
$section-header-border-top-bottom-width_handheld: 1px !default;
$section-header-border-top-bottom-color_handheld: #ddd !default;
$section-header-padding_handheld: ($padding-large-vertical / 2) $padding-large-horizontal !default;

$section-common-bottom-margin: 15px !default;
$section-common-padding: 10px 2% 10px 2% !default;
$section-common-padding-lg: 30px !default;
$section-common-padding_handheld: $padding-large-vertical $padding-small-horizontal !default;

$hotel-detail-section-body-padding: $section-common-padding !default;

// Site Header
// Usage: Override in a theme with column vars needed for each case
// Set 0 in any variable at any level will hide the respective container.
// e.g. by default, $site-header-brand-container-col-width-handheld is 0, hiding the brand container for handheld.
// Show brand container in a theme by setting a value other than 0.
// -------------------------

// $screen-sm to $screen-md
$site-header-brand-container-col-width: 6 !default;
$site-header-loyalty-container-col-width: 6 !default;

// $screen-md to $screen-lg
$site-header-brand-container-col-width-md: 3 !default;
$site-header-loyalty-container-col-width-md: 9 !default;

// $screen-lg +
$site-header-brand-container-col-width-lg: 3 !default;
$site-header-loyalty-container-col-width-lg: 9 !default;

// This is tablet in portrait orientation
$site-header-brand-container-col-width-tablet: 4 !default;
$site-header-loyalty-container-col-width-tablet: 8 !default;

// Handheld (<480px)
$site-header-brand-container-col-width-handheld: 4 !default;
$site-header-loyalty-container-col-width-handheld: 8 !default;

$site-header-background: $almost-white !default;
$site-header-text-color: $gray !default;
$site-header-border-bottom-color: $gray-lighter !default;
$site-header-border-bottom-width: 1px !default;
$site-header-logo-primary-file-path: "" !default; // Usage must include full theme path with theme's $assetPath context
$site-header-logo-primary-min-height: initial !default;
$site-header-logo-primary-min-height_handheld: $site-header-logo-primary-min-height !default;
$site-header-logo-primary-min-width: 100% !default;
$site-header-padding-top: $line-height-computed / 2 !default;
$site-header-padding-bottom: $line-height-computed / 2 !default;

$site-header-toolbar-container-border-top-color: $gray-lighter !default;
$site-header-toolbar-container-bg-color: #fff !default;
$site-header-toolbar-container-text-color: $site-header-text-color !default;
$site-header-link-text-decoration: initial !default;
$site-header-link-hover-text-decoration: initial !default;
$site-header-font-size: inherit !default;
$site-header-loyalty-link-hover-text-decoration: underline !default;
$site-header-points-text-color: $points-text-color !default;
$site-header-loyalty-nav-color: $text-color !default;
$site-header-loyalty-menu-item-padding: 8px 20px 10px !default;
$site-header-loyalty-nav-dropdown-min-width: 200px !default;
$site-header-customer-service-bg-color_handheld: transparent !default;

$header-brand-container-padding: $line-height-computed / 2 !default;

// Site Footer

$site-footer-background-color: $page-top-elements-background-color !default;
$site-footer-background-color_handheld: $site-footer-background-color !default;
$site-footer-text-color: $text-color !default;
$site-footer-link-color: $link-color !default;
$site-footer-link-hover-color: $link-hover-color !default;
$site-footer-link-text-decoration: inherit !default;
$site-footer-link-text-hover-decoration: inherit !default;

$site-footer-border-top-color: $base-border-color !default;
$site-footer-border-top-width: 0 !default;
$site-footer-border-top-width_handheld: 1px !default;

$site-footer-padding-top: $padding-large-vertical * 2 !default;
$site-footer-font-size: inherit !default;
$site-footer-line-height: inherit !default;

// Page Main Contents Container (main page contents)
// -------------------------
$main-contents-container-background-color: transparent !default;
$main-contents-container-border-color: $base-border-color !default;
$main-contents-container-border-width: 0 !default;

$main-contents-section-background-color: transparent !default;
$main-contents-section-bottom-margin: 0 !default;
$main-contents-section-header-background-color: #fff !default;
$main-contents-section-body-background-color: #fff !default;
$main-contents-section-footer-background-color: #fff !default;

$main-contents-section-alt-background-color: $gray-lighter !default;
$main-contents-section-alt-border-width: 1px !default;
$main-contents-section-alt-border-color: $gray-lighter !default;
$main-contents-section-alt-header-background-color: transparent !default;
$main-contents-section-alt-body-background-color: transparent !default;
$main-contents-section-alt-footer-background-color: transparent !default;
$main-contents-section-alt-section-body-padding: $section-common-padding !default;
$main-contents-section-alt-bottom-margin: $line-height-computed !default;

// Page Aside Contents Container (typically the sidebar; e.g. Itinerary on checkout)
// -------------------------
$aside-contents-container-background-color: transparent !default;
$aside-contents-container-border-color: $base-border-color !default;
$aside-contents-container-border-width: 0 !default;

$aside-contents-section-background-color: $almost-white !default;
$aside-contents-section-bottom-margin: $section-common-bottom-margin !default;
$aside-contents-section-text-color: $text-color !default;
$aside-contents-section-border-color: $base-border-color !default;
$aside-contents-section-border-width: 0 !default;
$aside-contents-section-padding: $padding-large-vertical 5% !default;
$aside-contents-section-box-shadow: $shadow-sm !default;

$aside-contents-section-link-color: $link-color !default;
$aside-contents-section-link-hover-color: $link-hover-color !default;

$aside-contents-section-top-header-background-color: transparent !default;
$aside-contents-section-top-header-text-color: $headings-text-color !default;
$aside-contents-container-close-btn-color: $close-color !default;

$aside-border-color: $gray-lighter !default;
$aside-contents-border-radius: 0 !default;

// Datepicker Component
// -------------------------

$datepicker-border-color: $gray-light !default;
$datepicker-thead-border: 1px solid  $gray-light !default;
$datepicker-day-border-color: $gray-light !default;
$datepicker-box-shadow: $shadow-md !default;
$datepicker-day-text-color: $text-color !default;
$datepicker-thead-border: 1px solid $gray-light !default;

$datepicker-thead-background-color: #fff !default;
$datepicker-thead-text-color: $text-color !default;

$datepicker-day-current-background-color: $brand-success !default;
$datepicker-day-current-text-color: #fff !default;

$datepicker-day-selected-background-color: $brand-primary !default;
$datepicker-day-selected-text-color: #fff !default;
$datepicker-day-hover-background-color: $datepicker-day-selected-background-color !default;
$datepicker-day-hover-text-color: $datepicker-day-selected-text-color !default;

$datepicker-date-empty-background-color: $gray-lighter !default;
$datepicker-date-empty-text-color: $gray-medium !default;

$datepicker-date-unselectable-background-color: $gray-lighter !default;
$datepicker-date-unselectable-text-color: $gray-medium !default;

$datepicker-input-background-color: #fff !default;
$datepicker-input-disabled-color: $btn-disabled-color !default;
$datepicker-input-disabled-background-color: $btn-disabled-bg-color !default;
$date-picker-active-link-color: $text-color !default;
$datepicker-selected-date-border-radius: 0 !default;
$datepicker-table-layout: auto !default;
$datepicker-table-layout_handheld: auto !default;

// Product Cards
// -------------------------
$product-body-bg-color: #fff !default;
$product-body-bg-color-selected: $product-body-bg-color !default;
$product-body-bg-color-selected_handheld: $product-body-bg-color-selected !default;
$product-body-bg-color-loading: $brand-active !default;
$product-body-bg-color-xsell: lighten($brand-special, 40%) !default;
$product-body-text-color-xsell: dynamicColor($product-body-bg-color-xsell) !default;
$product-body-footer-bg-color: $almost-white !default;
$product-body-footer-text-color: $text-muted !default;
$product-body-footer-font-size: $font-size-small !default;
$product-body-footer-text-color_handheld: $product-body-footer-text-color !default;
$product-body-border-radius: 0 !default;
$product-body-box-shadow: $shadow-md !default;
$product-detail-drawer-bg-color: #fff !default;
$product-additional-options-drawer-bg-color: $brand-active !default;
$product-additional-options-title-font-size: 1.6em !default;
$product-additional-options-title-text-align: center !default;
$product-aside-section-background-color: transparent !default;
$product-additional-options-panel-title-font-size-handheld: $font-size-xlarge_handheld !default;
$product-additional-options-card-title-font-size-handheld: $font-size-xlarge_handheld !default;
$product-additional-options-card-title-color_handheld: $text-color !default;

$products-list-bg-color_handheld: $almost-white !default;

$image-btn-border-radius: 0 !default;
$product-thumbnail-hotel-card-max-height: 250px !default;
$product-thumbnail-hotel-border-radius: $image-btn-border-radius !default;
$product-border-color: $gray-lighter !default;
$product-border-color-selected: $brand-success !default;
$product-border-color-selected_handheld: $product-border-color-selected !default;
$product-border-radius-selected_handheld: 0 !default;
$product-border-color-loading: darken($product-body-bg-color-loading, 40%) !default;
$product-body-border-color-xsell: $brand-special !default;
$product-body-border: 1px solid $product-border-color !default;
$product-border-width-loading: 1px !default;
$product-notification-border-color: $almost-white !default;
$product-notification-body-bg-color: $almost-white !default;
$product-notification-body-text-color: $gray-dark !default;
$product-kard-border-radius_handheld: 0 !default;
$product-header-link-text-decoration: none !default;

$product-leg-bg-color-active: $state-info-bg !default;

$product-col-interactive-background-color: $almost-white !default;
$product-col-interactive-text-color: $text-color !default;
$product-col-interactive-selected-background-color: $almost-white !default; // Note: room type radio selection uses col-interactive but NOT selected state
$product-col-interactive-selected-text-color: $text-color !default;

$product-check-color: $gray-medium !default;
$product-check-complete-color: $brand-success !default;

$product-room-selected-bg-color: $product-body-bg-color-selected !default;
$product-room-selected-bg-color-handheld: $product-room-selected-bg-color !default;
$product-remaining-text-color: $brand-warning !default;

$product-price-total-color: $text-color !default;
$product-price-font-size: 1.6em !default;
$product-price-container-bg-color: transparent !default;
$product-selected-price-container-bg-color: inherit !default;
$product-price-font-weight: bold !default;
$product-strikethrough-font-weight: $product-price-font-weight !default;
$product-price-container-text-alignment_handheld: right !default;
$product-loading-text-info-color: $brand-info !default;

$star-rating-active-text-color: $gray !default;

$list-product-selected-color: $brand-special !default;
$list-icon-bullet-color: $brand-special !default;
$list-icon-amenities-color: $text-color !default;

$products-loading-overlay-bg-color: rgba(255, 255, 255, .8) !default; // Overlays products when filtering
$similar-product-container-width: calc(100% - 2em) !default;

// Featured
$product-featured-border-color: $brand-featured !default;
$product-featured-bg-color: $brand-featured !default;
$product-featured-text-color: #fff !default;
$product-featured-header-size: $font-size-h2 !default;
$product-featured-header-padding: 6px 12px !default;

// Deals
$product-deal-bg-color: $brand-secondary !default;
$product-deal-text-color: #fff !default;
$product-deal-border-radius: 0 !default;

$product-deal-client-approved-bg-color: $product-deal-bg-color !default;
$product-deal-client-approved-text-color: $product-deal-text-color !default;
$product-deal-client-approved-bg-color_handheld: $product-deal-bg-color !default;
$product-deal-client-approved-text-color_handheld: $product-deal-text-color !default;

$product-deal-exclusive-bg-color: $product-deal-bg-color !default;
$product-deal-exclusive-text-color: $product-deal-text-color !default;
$product-deal-exclusive-bg-color_handheld: $product-deal-bg-color !default;
$product-deal-exclusive-text-color_handheld: $product-deal-text-color !default;

$product-deal-client-exclusive-bg-color: $product-deal-bg-color !default;
$product-deal-client-exclusive-text-color: $product-deal-text-color !default;

$product-deal-hotel-sale-bg-color: $product-deal-bg-color !default;
$product-deal-hotel-sale-text-color: $product-deal-text-color !default;

$product-deal-members-only-rate-bg-color: $product-deal-bg-color !default;
$product-deal-members-only-rate-text-color: $product-deal-text-color !default;

$product-deal-special-program-bg-color: $gray !default;
$product-deal-special-program-text-color: $product-deal-text-color !default;
$product-deal-special-program-text-align: left !default;
$product-deal-special-program-bg-color_handheld: $gray !default;
$product-deal-special-program-text-color_handheld: $product-deal-text-color !default;
$product-deal-special-program-body-bg-color: inherit !default;
$product-deal-special-program-body-text-color: $text-color !default;
$product-deal-special-program-image-button-text-color: #fff !default;
$product-deal-special-program-star-rating-color: inherit !default;
$product-deal-special-program-border-radius: inherit !default;
$product-deal-special-program-price-container-background-color: inherit !default;
$product-deal-special-program-body-border: 1px solid $product-border-color !default;
$product-deal-special-program-thc-body-border: 1px solid $product-border-color !default;
$product-deal-special-program-banner-height: initial !default;
$product-deal-special-program-banner-height_handheld: initial !default;

$product-deal-special-program-car-body-text-color: $text-color !default;
$product-deal-special-program-car-background-color: inherit !default;

$product-deal-special-program-thc-price-container-background-color: inherit !default;
$product-deal-special-program-thc-body-text-color: inherit !default;
$product-deal-special-program-thc-body-accent-color: inherit !default;
$product-deal-special-program-thc-body-bg-color: inherit !default;
$product-deal-special-program-thc-star-rating-color: inherit !default;

$product-benefits-background-color: $product-deal-special-program-body-bg-color !default;
$product-benefits-text-color: $product-deal-special-program-body-text-color !default;
$product-benefits-info-link-alignment: right !default;
$product-benefits-info-link-alignment_handheld: initial !default;
$product-benefits-info-link-decoration: inherit !default;
$product-deal-special-program-link-decoration: inherit !default;
$product-deal-special-program-thc-link-decoration: inherit !default;

$loyalty-deal-bg-color: $brand-info !default;
$loyalty-deal-text-color: dynamicColor($loyalty-deal-bg-color) !default;

$rate-deal-bg-color: $brand-info !default;
$rate-deal-text-color: dynamicColor($rate-deal-bg-color) !default;
$product-deal-font-weight: inherit !default;

//these styles adjusts the rate deal flag folds
$rate-deal-border-top: 10px solid darken($rate-deal-bg-color, 10%) !default;
$rate-deal-border-left: 10px solid transparent !default;
$rate-deal-position: absolute !default;
$rate-deal-bottom-position: -10px !default;
$rate-deal-content: '' !default;
$rate-deal-margins: 0 -1px 10px -25px !default;
$rate-deal-margin-left-xs: -15px !default;
$rate-deal-border-radius: 0 !default;
$rate-deal-max-width_handheld: inherit !default;
$rate-deal-font-size_handheld: inherit !default;
$rate-deal-line-height_handheld: inherit !default;
$product-deal-font-size_base: inherit !default;

$product-deal-included-flag-background-color: $brand-success !default;
$product-deal-included-flag-text-color: #fff !default;
$product-deal-content: '' !default; // set to none (no quotes) to eliminate the ribbon return
$product-deal-right-margin: $padding-small-horizontal !default;

$product-deal-padding-horizontal: $padding-small-horizontal !default;
$product-deal-padding-vertical: $padding-base-vertical/2 !default;

$promotion-notation-bg-color: $product-deal-bg-color !default;
$promotion-notation-bg-color_handheld: $promotion-notation-bg-color !default;
$promotion-notation-text-color: dynamicColor($promotion-notation-bg-color) !default;
$promotion-notation-text-color_handheld: $promotion-notation-text-color !default;
$promotion-notation-padding: $product-deal-padding-vertical $product-deal-padding-horizontal !default;
$promotion-notation-border-radius: 0 !default;
$promotion-notation-font-weight: inherit !default;
$product-promo-text-transform: uppercase !default;
$product-promo-border: 1px dotted $base-border-color !default;

$specials-notation-font-weight: 700 !default;

// Check mark loader - Used in Add to Cart
// -------------------------
$checkloader-size: 8em !default;
$checkloader-spin-time: 1s !default;
$checkloader-check-thickness: 2px !default;
$checkloader-check-color: $brand-featured !default;
$checkloader-check-success-color: $brand-success !default;
$checkloader-check-height: $checkloader-size/2 !default;
$checkloader-check-width: $checkloader-check-height/2 !default;
$checkloader-check-left: ($checkloader-size/6 + $checkloader-size/12) !default;

// Branded Flights
// -------------------------
$branded-flight-product-body-stripe-background-color: $gray-lighter !default;
$branded-flight-cell-background-color: lighten($gray-dark, 60%) !default;
$branded-flight-cell-border-color: $base-border-color !default;
$branded-flight-cell-background-color-secondary: darken($branded-flight-cell-background-color, 15%) !default;
$branded-flight-cell-text-color-secondary: dynamicColor($branded-flight-cell-background-color-secondary) !default;
$branded-flight-cell-background-color-hover: darken($brand-primary, 8%) !default;
$branded-flight-cell-text-color: dynamicColor($branded-flight-cell-background-color) !default;
$branded-flight-cell-header-color: $text-color !default;
$branded-flight-cell-expanded-background-color: $branded-flight-cell-background-color !default;
$branded-flight-cell-coach-expanded-background-color: $branded-flight-cell-background-color !default;
$branded-flight-cell-business-expanded-background-color: $branded-flight-cell-background-color-secondary !default;
$branded-flight-cell-other-expanded-background-color: $gray-light !default;
$branded-flight-cell-expanded-link-text-color: dynamicColor($branded-flight-cell-expanded-background-color) !default;

$branded-flight-cell-expanded-text-color: dynamicColor($branded-flight-cell-expanded-background-color) !default;
$branded-flight-cell-expanded-header-border: $gray-medium !default;
$branded-flight-cell-selected-background-color: $brand-primary !default;
$branded-flight-cell-selected-text-color: dynamicColor($branded-flight-cell-selected-background-color) !default;
$branded-flight-cell-container-active-border-color: $gray-lighter !default;
$branded-flight-cell-container-active-border-width: 2px !default;
$branded-flight-cell-promoted-background-color: $brand-special !default;
$branded-flight-cell-promoted-text-color: dynamicColor($branded-flight-cell-promoted-background-color) !default;
$branded-flight-cell-selected-active-most-popular-color: $brand-primary !default;
$branded-flight-cell-toggle-view-details-color: $gray-light !default;

$branded-fare-color-border-promoted: $branded-flight-cell-promoted-background-color !default;
$branded-flight-layover-span: #000 !default;
$branded-flight-cell-button-background-color: $branded-flight-cell-selected-background-color !default;
$branded-flight-cell-button-text-color: dynamicColor($branded-flight-cell-button-background-color) !default;
$branded-flight-first-cell-selected-flag-background-color: $brand-success !default;
$branded-flight-first-cell-selected-flag-text-color: dynamicColor($branded-flight-first-cell-selected-flag-background-color) !default;
$branded-flight-second-cell-selected-flag-background-color: $brand-success !default;
$branded-flight-second-cell-selected-flag-text-color: dynamicColor($branded-flight-second-cell-selected-flag-background-color) !default;
$branded-flight-background-color-button-selected-hover: #fff !default;
$branded-flight-selected-button-background-color: $brand-success !default;

$branded-flight-column-header-border-color: $branded-flight-cell-border-color !default;
$branded-flight-column-header-border-bottom-width: 2px !default;
$branded-flight-column-header-border-bottom-color: $gray-lighter !default;
$branded-flight-column-header-cell-text-color: $text-color !default;
$branded-flight-column-header-cell-link-color: $link-color !default;
$branded-flight-column-header-cell-price-color: $branded-flight-cell-header-color !default;
$branded-flight-column-header-cell-background-color: $branded-flight-cell-background-color !default;
$branded-flight-column-header-cell-active-background-color: #fff !default;
$branded-flight-column-header-toggle-link-color: $link-color !default;
$branded-flight-column-header-toggle-border-color: $branded-flight-cell-border-color !default;

$branded-flight-cabin-check-complete-color: $brand-success !default;
$branded-flight-cabin-flag-message-text-color: #fff !default;

$branded-flight-column-background-color: #fff !default;
$branded-flight-column-background-color-hover: #fff !default;
$branded-flight-column-selected-background-color: #fff !default;
$branded-flight-column-selected-text-color: $text-color !default;

$branded-flight-selected-date-capitalization: none !default;

$branded-flight-upgrade-footer-text-color: dynamicColor($branded-flight-cell-expanded-background-color) !default;

// Product Deal Featured Flights
// -------------------------
$product-featured-background-color: $brand-info !default;
$product-featured-name-text-color: $product-featured-text-color !default;
$product-featured-font-family: $font-family-base !default;
$product-featured-font-size: $font-size-h3 !default;
$product-featured-font-weight: $headings-font-weight !default;
$product-featured-deal-border: $product-body-border !default;
$product-featured-deal-selected-outbound-font-size: $font-size-large !default;
$product-featured-deal-selected-outbound-font-size_handheld: $font-size-h4_handheld !default;
$product-featured-deal-name-font-size_handheld: $font-size-large_handheld !default;

// date-carousel
// -------------------------
$date-carousel-li-background-color: $gray-lighter !default;
$date-carousel-li-text-color: dynamicColor($date-carousel-li-background-color) !default;
$date-carousel-li-text-hover-color: $date-carousel-li-text-color !default;
$date-carousel-li-price-text-color: $brand-primary !default;

$date-carousel-li-background-color-hover: $gray-light !default;
$date-carousel-li-text-color-hover: darken($gray-lighter, 25%) !default;

$date-carousel-li-selected-background-color: $gray-light !default;
$date-carousel-li-selected-text-color: $text-color !default;
$date-carousel-li-price-selected-text-color: $text-color !default;
$date-carousel-li-icon-text-color-hover: #fff !default;

$date-carousel-li-disabled-text-color: $text-muted !default;
$date-carousel-li-disabled-background-color: lighten($gray-light, 20%) !default;

$date-carousel-li-unavailable-background-color: $gray-lighter !default;
$date-carousel-li-unavailable-text-color: $text-color !default;
$date-carousel-li-unavailable-price-text-color: $text-color !default;

// flight-matrix a.k.a 7x7 calendar
// -------------------------
$flight-matrix-cell-text-color: $gray-dark !default;
$flight-matrix-cell-hover-text-color: $flight-matrix-cell-text-color !default;
$flight-matrix-headers-background-color: $almost-white !default;
$flight-matrix-headers-background-color-handheld: $almost-white !default;
$flight-matrix-headers-text: #000 !default;
$flight-matrix-headers-text-handheld: #000 !default;
$flight-matrix-headers-weight: 500 !default;
$flight-matrix-selected-dates-background-color: $brand-primary !default;
$flight-matrix-selected-dates-text: white !default;
$flight-matrix-selected-cell-outline-color: $brand-primary !default;
$flight-matrix-selected-cell-background-color: transparent !default;
$flight-matrix-selected-cell-text: $flight-matrix-cell-text-color !default;
$flight-matrix-selected-cell-weight: inherit !default;
$flight-matrix-border-color: $gray-lighter !default;
$flight-matrix-lowest-price-color: transparent !default;
$flight-matrix-lowest-price-selected-cell-background-color: $flight-matrix-lowest-price-color !default;
$flight-matrix-lowest-price-text-color: $brand-success !default;
$flight-matrix-lowest-price-outline-color: inherit !default;
$flight-matrix-lowest-price-icon-color: $brand-success !default;
$flight-matrix-lowest-price-font-weight: 400 !default;
$flight-matrix-lowest-price-icon-font-size: inherit !default;
$flight-matrix-disabled-cell-text-color: $gray-medium !default;
$flight-matrix-disabled-cell-background-color: transparent !default;
$flight-matrix-cell-hover-background-color: $brand-primary !default;
$flight-matrix-cell-hover-background-opacity: 0.1 !default;
$flight-matrix-cell-hover-text-decoration: underline !default;
$flight-matrix-legend-label-text-color: $brand-success !default;
$flight-matrix-legend-lowest-price-icon-background-color: transparent !default;
$flight-matrix-legend-lowest-price-icon-text-color: $brand-success !default;
$flight-matrix-price-font-size: $font-size-base !default;
$flight-matrix-total-price-font-size_handheld: $font-size-base_handheld !default;
$flight-matrix-cell-or-text-hover-color: $flight-matrix-cell-hover-text-color !default;
$flight-matrix-headers-font-size: inherit !default;
$flight-matrix-headers-font-size_handheld: inherit !default;

// leg-based-flight-matrix a.k.a. 3 day calendar
// -------------------------

$three-day-calendar-default-border-color: $gray-lighter !default;
$three-day-calendar-default-border-width: 1px !default;

// Nav buttons
$three-day-calendar-arrow-btn-bg-color: $almost-white !default;
$three-day-calendar-arrow-btn-icon-color: $brand-primary !default;
$three-day-calendar-arrow-btn-border-top-color: $three-day-calendar-default-border-color !default;
$three-day-calendar-arrow-btn-border-top-width: $three-day-calendar-default-border-width !default;
$three-day-calendar-arrow-btn-border-right-color: $three-day-calendar-default-border-color !default;
$three-day-calendar-arrow-btn-border-right-width: $three-day-calendar-default-border-width !default;
$three-day-calendar-arrow-btn-border-bottom-color: $three-day-calendar-default-border-color !default;
$three-day-calendar-arrow-btn-border-bottom-width: $three-day-calendar-default-border-width !default;
$three-day-calendar-arrow-btn-border-left-color: $three-day-calendar-default-border-color !default;
$three-day-calendar-arrow-btn-border-left-width: $three-day-calendar-default-border-width !default;

// Nav buttons (hover)
$three-day-calendar-arrow-btn-bg-color-hover: $brand-primary !default;
$three-day-calendar-arrow-btn-icon-color-hover: white !default;
$three-day-calendar-arrow-btn-border-color-hover: $brand-primary !default;

// Lead Price Groups
$three-day-calendar-lpg-cell-date-text-color: $gray-dark !default;
$three-day-calendar-lpg-cell-price-text-color: $gray-dark !default;
$three-day-calendar-lpg-cell-price-text-size: $font-size-large !default;
$three-day-calendar-lpg-cell-bg-color: $almost-white !default;
$three-day-calendar-lpg-cell-border-top-color: $three-day-calendar-default-border-color !default;
$three-day-calendar-lpg-cell-border-top-width: $three-day-calendar-default-border-width !default;
$three-day-calendar-lpg-cell-border-right-color: $three-day-calendar-default-border-color !default;
$three-day-calendar-lpg-cell-border-right-width: $three-day-calendar-default-border-width !default;
$three-day-calendar-lpg-cell-border-bottom-color: $three-day-calendar-default-border-color !default;
$three-day-calendar-lpg-cell-border-bottom-width: $three-day-calendar-default-border-width !default;
$three-day-calendar-lpg-cell-border-left-color: $three-day-calendar-default-border-color !default;
$three-day-calendar-lpg-cell-border-left-width: $three-day-calendar-default-border-width !default;

// Lead Price Groups -- Handheld
$three-day-calendar-lpg-cell-border-top-color_handheld: $three-day-calendar-default-border-color !default;
$three-day-calendar-lpg-cell-border-top-width_handheld: $three-day-calendar-default-border-width !default;
$three-day-calendar-lpg-cell-border-right-color_handheld: $three-day-calendar-default-border-color !default;
$three-day-calendar-lpg-cell-border-right-width_handheld: $three-day-calendar-default-border-width !default;
$three-day-calendar-lpg-cell-border-bottom-color_handheld: $three-day-calendar-default-border-color !default;
$three-day-calendar-lpg-cell-border-bottom-width_handheld: $three-day-calendar-default-border-width !default;
$three-day-calendar-lpg-cell-border-left-color_handheld: $three-day-calendar-default-border-color !default;
$three-day-calendar-lpg-cell-border-left-width_handheld: $three-day-calendar-default-border-width !default;


// Lead Price Groups (Hover)
$three-day-calendar-lpg-cell-text-color-hover: $brand-primary !default;
$three-day-calendar-lpg-cell-bg-color-hover: $almost-white !default;
$three-day-calendar-lpg-cell-border-color-hover: $three-day-calendar-default-border-color !default;

// Lead Price Groups (Selected)
$three-day-calendar-lpg-cell-text-color-selected: $brand-primary !default;
$three-day-calendar-lpg-cell-bg-color-selected: white !default;
$three-day-calendar-lpg-cell-border-top-color-selected: $brand-primary !default;
$three-day-calendar-lpg-cell-border-top-width-selected: 5px !default;
$three-day-calendar-lpg-cell-border-right-color-selected: $gray-lighter !default;
$three-day-calendar-lpg-cell-border-right-width-selected: $three-day-calendar-default-border-width !default;
$three-day-calendar-lpg-cell-border-bottom-color-selected: white !default;
$three-day-calendar-lpg-cell-border-bottom-width-selected: $three-day-calendar-default-border-width !default;
$three-day-calendar-lpg-cell-border-left-color-selected: $gray-lighter !default;
$three-day-calendar-lpg-cell-border-left-width-selected: $three-day-calendar-default-border-width !default;

// Lead Price Groups (Selected) - Handheld
$three-day-calendar-lpg-cell-border-top-color-selected_handheld: $brand-primary !default;
$three-day-calendar-lpg-cell-border-top-width-selected_handheld: 5px !default;
$three-day-calendar-lpg-cell-border-right-color-selected_handheld: $gray-lighter !default;
$three-day-calendar-lpg-cell-border-right-width-selected_handheld: $three-day-calendar-default-border-width !default;
$three-day-calendar-lpg-cell-border-bottom-color-selected_handheld: white !default;
$three-day-calendar-lpg-cell-border-bottom-width-selected_handheld: $three-day-calendar-default-border-width !default;
$three-day-calendar-lpg-cell-border-left-color-selected_handheld: $gray-lighter !default;
$three-day-calendar-lpg-cell-border-left-width-selected_handheld: $three-day-calendar-default-border-width !default;

// Step Indicator
// -------------------------
$step-indicator-step-background-color: $brand-info !default;
$step-indicator-step-text-color: #fff !default;

// Number Stepper
// -------------------------
$number-stepper-bg-color-hover: $btn-primary-bg-color-hover !default;
$number-stepper-icon-color-disabled: $btn-disabled-bg-color !default;
$number-stepper-icon-size: 2em !default;
$number-stepper-flex-align: center !default;

// Map Markers
// -------------------------
$hotel-view-toggle-border-radius: 0 !default;
$filter-bing-map-container-height: 580px !default;
$map-marker-color: $brand-primary !default;
$map-marker-cluster-text-color: #fff !default;
$map-marker-cluster-background-color: $map-marker-color !default;
$map-marker-cluster-border-width: 1px !default;
$map-marker-cluster-border-color: $map-marker-cluster-text-color !default;
$map-marker-cluster-outline-color: darken($map-marker-cluster-background-color, 20%) !default;
$map-marker-outline-color: $map-marker-cluster-outline-color !default;
$map-marker-link-color: $link-color !default;

// Marker active state
$map-marker-active-text-color: $text-color !default;
$map-marker-active-background-color: $brand-special !default;
$map-marker-active-border-color: darken($map-marker-active-background-color, 20%) !default;
$map-marker-active-outline-color: $map-marker-outline-color !default;

// Map hotel mini card
$map-product-mini-background-color: #fff !default;
$map-product-mini-text-color: $text-color !default;
$map-product-mini-border-color: darken($map-product-mini-background-color, 15%) !default;
$map-product-mini-media-header-color: inherit !default;

// Filter Components
// -------------------------

$filter-section-divider-color: $gray-light !default;
$filter-h3-font-size: $font-size-large !default;

// Slider Filter
$filter-slider-track-background-color: $gray-lighter !default;
$filter-slider-selection-background-color: $brand-featured !default;
$filter-slider-handle-background-color: #666 !default;
$filter-slider-handle-border-color: $filter-slider-handle-background-color !default;
$filter-slider-handle-border-width: 1px !default;

// Hotel Map/List View Toggle
$toggle-view-label-background-color: $link-color !default;
$toggle-view-label-background-color-hover: $link-hover-color !default;
$toggle-view-label-text-color: #fff !default;

// Typeahead/Autocomplete
$tt-dropdown-bg-color: #fff !default;
$tt-dropdown-text-color: $text-color !default;
$tt-dropdown-text-color-light: $gray !default;
$tt-dropdown-border-color: darken($tt-dropdown-bg-color, 15%) !default;
$tt-dropdown-hover-bg-color: $brand-active !default;
$tt-dropdown-hover-text-color: $text-color !default;
$tt-dropdown-highlight-text-color: $link-color !default;
$tt-dropdown-empty-message-background-color: #ffc !default;
$tt-dropdown-max-height: 200px !default;
$header-dropdown-font-size: $font-size-small !default;

// Points Slider Component
// -------------------------

$points-slider-background-color: $filter-slider-track-background-color !default;
$points-slider-selection-background-color: $points-text-color !default;
$points-slider-handle-background-color: $filter-slider-handle-background-color !default;
$points-slider-handle-text-color: $points-slider-selection-background-color !default;

// DashCart
// -------------------------

$dashcart-background-color: #fff !default;
$dashcart-header-panel-border-radius: 0 !default;
$dashcart-footer-border-radius: 0 !default;
$dashcart-footer-btn-radius: 0 !default;
$dashcart-footer-padding: 0 !default;

$dashcart-toggle-open-background-color: $gray-lighter !default;
$dashcart-toggle-open-background-color-hover: darken($gray-lighter, 5%) !default;
$dashcart-toggle-border-color: $dashcart-toggle-open-background-color-hover !default;
$dashcart-toggle-open-text-color: $text-color !default;
$dashcart-toggle-open-text-color-hover: $text-color !default;
$dashcart-toggle-collapsed-background-color: $dashcart-toggle-open-background-color !default;
$dashcart-toggle-collapsed-background-color-hover: darken($gray-lighter, 5%) !default;
$dashcart-toggle-collapsed-text-color: $dashcart-toggle-open-text-color !default;
$dashcart-toggle-collapsed-text-color-hover: $dashcart-toggle-open-text-color-hover !default;

$dashcart-product-background-color: transparent !default;
$dashcart-product-header-background-color: transparent !default;
$dashcart-product-unchosen-header-background-color: transparent !default;
$dashcart-product-unit-background-color: #fff !default;
$dashcart-product-header-background-color-xsell: $brand-special !default;
$dashcart-product-header-color-xsell: dynamicColor($dashcart-product-header-background-color-xsell) !default;

// DashBar
// -------------------------
$dashbar-padding: 16px !default; // SG TODO: This is a magic number; should be based on $padding-large-horizontal and $padding-large-vertical (and not 16px static) - learned this isn't even used as a padding var; we should rename
$dashbar-bg-color: $gray-lighter !default;
$dashbar-bg-color-hover: darken($dashbar-bg-color, 8%) !default;
$dashbar-contents-bg-color: $gray-lighter !default;
$dashbar-link-color: $link-color !default;
$dashbar-link-bg-color: $dashbar-bg-color !default;
$dashbar-border-color: $dashbar-bg-color !default;

$dashbar-traveler-text: $gray !default;
$dashbar-traveler-table-row: $gray-medium !default;
$dashbar-traveler-search-link-info: $gray-light !default;

$dashbar-dropdown-bg-color: $dashbar-bg-color !default;

// Handheld DashBar
//--------------------------
$dashbar-handheld-background-color: $brand-primary !default;
$dashbar-handheld-text-color: dynamicColor($dashbar-handheld-background-color) !default;

// Shopping Cart
// -------------------------
$shopping-cart-background-color: $gray-lighter !default;
$shopping-cart-header-background-color: $brand-primary !default;
$shopping-cart-header-color: dynamicColor($shopping-cart-header-background-color) !default;
$shopping-cart-section-background-color: $almost-white !default;
$shopping-cart-section-visited-background-color: #fff !default;
$shopping-cart-section-header-heading-font-weight: 700 !default;
$shopping-cart-section-header-trip-itinerary-color: $brand-primary !default;
$shopping-cart-footer-border-color: #fff !default;
$shopping-cart-footer-button-background-color: #bbb !default;
$shopping-cart-dropdown-background-color: #fff !default;

// Ancillaries
// SG TODO: Lots of refactoring here
// -------------------------
$ancillary-header-text-color: $gray-dark !default;
$ancillary-header-title-text-color: $almost-white !default;
$ancillary-header-subtitle-text-color: $gray-medium !default;

$ancillary-section-body-bg-color: $almost-white !default;
$ancillary-section-body-padding: $padding-common-md !default;

$ancillary-section-body-bg-color-handheld: $gray-lighter !default;

$ancillary-title-color-handheld: $gray-dark !default;
$ancillary-title-font-size-handheld: $padding-common-md !default;
$ancillary-title-margin-top-handheld: 0 !default;
$ancillary-subtitle-color-handheld: $gray !default;
$ancillary-subtitle-font-size-handheld: 14px !default;
$ancillary-subtitle-margin-top-handheld: $ancillary-title-margin-top-handheld !default;

$ancillary-hub-continue-button-width-handheld: 100% !default;
$ancillary-hub-continue-button-padding-left-handheld: 0 !default;
$ancillary-hub-continue-button-padding-right-handheld: 0 !default;
$ancillary-hub-continue-button-bottom-handheld: 0 !default;
$ancillary-hub-continue-button-border-radius-handheld: 0 !default;
$ancillary-hub-continue-button-height-handheld: 50px !default;
$ancillary-hub-continue-button-font-size-handheld: 15px !default;
$ancillary-hub-continue-button-z-index-handheld: 999 !default;

// Compound add-ons module
$ancillary-addons-product-border-color: $gray-lighter !default;
$ancillary-addons-product-background-color: $almost-white !default;
$ancillary-addons-product-contents-border-color: $gray-light !default;
$ancillary-addons-product-selection-border-bottom-color: $gray !default;
$ancillary-addons-product-table-border-color: $almost-white !default;

// Col Interactive
$ancillary-col-interactive-link-color: $link-color !default;
$ancillary-col-interactive-background-color: #fff !default;
$ancillary-col-interactive-hover-background-color: $ancillary-col-interactive-background-color !default;
$ancillary-col-interactive-text-color: $brand-primary !default;
$ancillary-col-interactive-selected-background-color: $gray !default;
$ancillary-col-interactive-selected-text-color: dynamicColor($brand-primary) !default;

// Bags Module
$ancillary-bag-apply-to-all-margin-bottom: ($padding-small-vertical * 6) !default;
$ancillary-bag-dimensions-margin-bottom: ($padding-small-vertical * 2) !default;
$ancillary-bag-dimensions-margin-left: ($padding-small-vertical * 3) !default;
$ancillary-bag-dimensions-margin-top: 0 !default;
$ancillary-bag-dimensions-margin-right: ($padding-small-vertical * 6) !default;
$ancillary-bag-dimensions-margin-right_handheld: ($padding-small-vertical * 4) !default;
$ancillary-bag-traveler-item-padding: ($padding-base-vertical * 2) !default;
$ancillary-bag-name-arrow-horizontal-padding: $padding-small-vertical !default;
$ancillary-bag-name-base-padding: $padding-large-horizontal / 2 !default;
$ancillary-bag-border-color: $gray-light !default;

// Bundles
$ancillary-bundles-selection-savings-background-color: $brand-special !default;

// (New) Seat Map
$seat-map-tab-header-background-color: $gray-medium !default;
$seat-map-tab-header-background-hover-color: $seat-map-tab-header-background-color !default;
$seat-map-tab-header-text-color: dynamicColor($seat-map-tab-header-background-color) !default;
$seat-map-tab-header-background-color_handheld: $seat-map-tab-header-background-color !default;
$seat-map-tab-header-text-color: #fff !default;
$seat-map-tab-header-active-background-color: $gray-light !default;
$seat-map-tab-header-active-text-color: dynamicColor($seat-map-tab-header-active-background-color) !default;
$seat-map-tab-header-active-text-color: #fff !default;
$seat-map-tab-header-active-border-color: #b5b9b9 !default;

$seat-map-tab-traveler-background-color: $ancillary-section-body-bg-color !default;
$seat-map-tab-traveler-text-color: $text-muted !default;
$seat-map-tab-traveler-active-text-color: $seat-map-tab-header-active-background-color !default;
$seat-map-tab-traveler-icon-user-color: $gray-light !default;

$seat-map-tab-traveler-timeline-width: 2px !default; // Set to 0 to hide the timeline
$seat-map-tab-traveler-timeline-color: currentColor !default;

$seat-map-legend-background-color: $ancillary-section-body-bg-color !default;
$seat-map-legend-border-width: 2px !default;
$seat-map-legend-border-color: $gray-lighter !default;

$seat-map-fuselage-line-color: #999 !default;
$seat-map-fuselage-wing-color: $gray-lighter !default;

$seat-map-exit-row-arrow-color: red !default;
$seat-map-unavailable-seat-bg-color: #bbb !default;
$seat-map-seat-selecting-bg-color: $gray-light !default;
$seat-map-seat-selecting-text-color: dynamicColor($seat-map-seat-selecting-bg-color) !default;
$seat-map-seat-selected-bg-color: $brand-success !default;
$seat-map-seat-selected-text-color: dynamicColor($seat-map-seat-selected-bg-color) !default;

$seat-map-loading-spinner-left-spacing: 3px !default;
$seat-map-loading-spinner-top-spacing: 0px !default;

$seat-map-economy-fuselage-color: $gray-lighter !default;

$seat-map-economy-seat-available-bg-color: $brand-info !default;
$seat-map-economy-seat-available-text-color: dynamicColor($seat-map-economy-seat-available-bg-color) !default;

$seat-map-economy-seat-premium-available-bg-color: $brand-info !default;
$seat-map-economy-seat-premium-available-text-color: #fff !default;

// Seat premium increment colors, 1-6. Price high>low sorted (seat-premium-1 is most expensive)
$seat-map-economy-seat-premium-1-available-bg-color: darken($brand-info, 50%) !default;
$seat-map-economy-seat-premium-1-available-text-color: $seat-map-economy-seat-premium-available-text-color !default;

$seat-map-economy-seat-premium-2-available-bg-color: darken($brand-info, 40%) !default;
$seat-map-economy-seat-premium-2-available-text-color: $seat-map-economy-seat-premium-available-text-color !default;

$seat-map-economy-seat-premium-3-available-bg-color: darken($brand-info, 30%) !default;
$seat-map-economy-seat-premium-3-available-text-color: $seat-map-economy-seat-premium-available-text-color !default;

$seat-map-economy-seat-premium-4-available-bg-color: darken($brand-info, 20%) !default;
$seat-map-economy-seat-premium-4-available-text-color: $seat-map-economy-seat-premium-available-text-color !default;

$seat-map-economy-seat-premium-5-available-bg-color: darken($brand-info, 10%) !default;
$seat-map-economy-seat-premium-5-available-text-color: $seat-map-economy-seat-premium-available-text-color !default;

$seat-map-economy-seat-premium-6-available-bg-color: $brand-info !default;
$seat-map-economy-seat-premium-6-available-text-color: $seat-map-economy-seat-premium-available-text-color !default;

$seat-map-popover-background-color: $gray !default;
$seat-map-popover-footer-border-color: $gray-lighter !default;

// Seat Map Preview
$seat-map-preview-bg-color: $gray-lighter !default;
$seat-map-preview-table-border-width: 3px !default;
$seat-map-preview-table-border-color: $gray-lighter !default;
$seat-map-preview-text-color: #666 !default;
$seat-map-preview-economy-seat-background-color: $seat-map-economy-seat-available-bg-color !default;

// Seat Map Handheld
$seat-map-header-bg-color_handheld: $site-header-background !default;
$seat-map-popover-text-special-text-color: $brand-special !default;
$seat-map-available-bg-color_handheld: $brand-primary !default;
$seat-map-available-text-color_handheld: #fff !default;
$seat-map-unavailable-cross-bg-color_handheld: $brand-primary !default;
$seat-map-available-fuselage-bg-color_handheld: #fff !default;

// Marketing Ad
// -------------------------
$marketing-ad-bg-color: $brand-featured !default;
$marketing-ad-text-color: $text-color !default;

// Dropdowns
// -------------------------
$dropdown-bg: #fff !default;
$dropdown-divider-bg: #e5e5e5 !default;
$dropdown-border: $dropdown-bg !default;

$dropdown-link-active-color: #fff !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-color: $gray-dark !default;
$dropdown-link-hover-color: #fff !default;
$dropdown-link-hover-bg: $dropdown-link-active-bg !default;

$dropdown-link-disabled-color: $gray-light !default;

$dropdown-header-color: $gray-dark !default;

$dropdown-caret-color: #000 !default;

// Checkout
// -------------------------
$checkout-form-container-padding: 0 8px !default; // bootstrap's default
$checkout-form-container-padding_handheld: 0 8px !default; // Case for when theme sets $checkout-form-container-padding such that in handheld, the padding might be incorrect
$checkout-form-container-padding_tablet: 0 8px !default; // Case for when theme sets $checkout-form-container-padding such that in tablet + portrait, the padding might be incorrect

$checkout-section-margin: 0 !default;
$checkout-section-header-background-color: #fff !default;
$checkout-section-header-text-color: $section-header-text-color !default;
$checkout-section-header-padding: 10px 0 !default;
$checkout-section-body-background-color: #fff !default;
$checkout-section-body-padding: 10px 2% !default;

$checkout-aside-section-component-text-info-color: $brand-info !default;

$checkout-pricing-summary-caption-padding: 0 5px !default;
$checkout-pricing-summary-total-th-padding: 0 5px 5px !default;
$checkout-pricing-summary-total-td-padding: 5px !default;
$pricing-summary-font-weight: $headings-font-weight-h2 !default;

$checkout-travelers-details-legend-background-color: $gray-lighter !default;
$checkout-travelers-details-legend-padding: 10px 8px !default;
$checkout-travelers-details-legend-text-transform: none !default;
$checkout-travelers-details-legend-font-weight: bold !default;

$checkout-financial-header-background-color: #fff !default;
$checkout-financial-header-text-color: $gray-dark !default;
$checkout-financial-body-background-color: #fff !default;
$checkout-financial-purchase-button-margin: 0 !default;
$checkout-purchase-button-alignment: center !default;

$checkout-aside-container-padding: 0 8px !default; // bootstrap's default
$checkout-aside-section-body-background-color: $aside-contents-section-background-color !default;
$checkout-aside-section-header-background-color: transparent !default;
$checkout-aside-section-header-border-color: $gray-dark !default;
$checkout-aside-section-header-text-color: $gray-dark !default;
$checkout-aside-section-component-h4-font-weight: 500 !default;
$checkout-aside-section-component-border-color: $gray-lighter !default;

$checkout-payment-option-icon__creditcard: url('#{$assetsPath}/checkout/payment_options_creditcard.svg') !default;
$checkout-payment-option-icon__marcus-pay: url('#{$assetsPath}/checkout/payment_options_marcuspay_dark.png') !default;
$checkout-payment-option-icon__paypal: url('#{$assetsPath}/checkout/payment_options_paypal.png') !default;
$checkout-payment-option-icon__banktransfer: url('#{$assetsPath}/checkout/payment_options_banktransfer.svg') !default;
$checkout-traveler_profile_add_user_icon: url('#{$assetsPath}/checkout/traveler_profile_add_user_icon.svg') !default;
$checkout-traveler_profile_user_icon: url('#{$assetsPath}/checkout/traveler_profile_user_icon.svg') !default;
$checkout-payment-option-icon-width: 75px !default;
$checkout-payment-option-icon-height: 25px !default;
$checkout-payment-option-icon-lg-width: 100px !default;
$checkout-payment-option-icon-lg-height: 50px !default;
$checkout-terms-conditions-background-color: transparent !default;
$checkout-terms-conditions-text-color: inherit !default;
$checkout-display-terms-font-size: inherit !default;
$checkout-terms-conditions-font-size: inherit !default;
$checkout-display-terms-line-height: inherit !default;

// Confirmation
// -------------------------
$confirmation-booking-confirmed-check-success: $brand-success !default;
$confirmation-base-padding: 8px !default;
$confirmation-section-margin-vertical: 80px !default;
$confirmation-border-header-width: 2px !default;
$confirmation-border-width: 1px !default;
$confirmation-border-color: $gray-lighter !default;
$confirmation-subheader-text-case: none !default;
$confirmation-section-body-bg: $almost-white !default;

// Dash summary
// -------------------------
$dashcart-summary-booking-confirmed-check-success: $brand-success !default;
$dashcart-summary-section-margin-vertical: 80px !default;
$dashcart-summary-base-padding: 8px !default;
$dashcart-summary-border-header-width: 2px !default;
$dashcart-summary-border-width: 1px !default;
$dashcart-summary-border-color: $gray-lighter !default;
$dashcart-summary-subheader-text-case: none !default;

// Ancillary Hub Page
// -------------------------
$ancillary-hub-tile-body-text-color: $text-color !default;
$ancillary-hub-tile-background-color: transparent !default;
$ancillary-hub-tile-background-color_handheld: #fff !default;
$ancillary-hub-tile-spinner-padding: ($padding-large-vertical * 4.5) 0 !default;
$ancillary-hub-tile-body-padding: 30px 20px !default;
$ancillary-hub-tile-footer-padding: 10px 20px !default;
$ancillary-hub-tile-success-icon-color: $brand-success !default;
$ancillary-hub-tile-product-font-size: 16px !default;
$ancillary-hub-tile-width: 140px !default;
$ancillary-hub-tile-height: 120px !default;
$ancillary-hub-tile-margin: 15px !default;
$ancillary-hub-tile-content-padding: 5px !default;
$ancillary-hub-tile-lead-pricing-font-size: 14px !default;

// Trip Summary
// -------------------------
$trip-summary-text-color: $gray !default;
$trip-summary-ellipse-color: $brand-primary !default;
$trip-summary-plane-color: #fff !default;
$trip-summary-product-border-radius: 0 !default;
$trip-summary-total-price-font-weight: $text-strong-font-weight !default;
$trip-summary-total-price-value-font-weight: $text-strong-font-weight !default;

// Seat Map Flight Component
// -------------------------
$aircraft-cabin-border-color: $gray-light !default;
$aircraft-cabin-background-color: transparent !default;
$aircraft-seat-unavailable-background-color: $gray-lighter !default;
$aircraft-seat-unavailable-border-color: $gray-medium !default;
$aircraft-facility-background-color: $gray-light !default;
$aircraft-facility-text-color: $text-color !default;
$aircraft-wing-background-color: $gray-lighter !default;
$aircraft-exit-emergency-background-color: lighten($brand-danger, 45%) !default;
$aircraft-exit-emergency-border-color: $brand-danger !default;

// Web Chat
// -------------------------
$webchat-online-nav-background-color: $brand-primary !default;
$webchat-offline-nav-background-color: $gray !default;
$webchat-background-color: #fff !default;
$webchat-nav-color: #fff !default;
$webchat-border-color: $webchat-online-nav-background-color !default;
$webchat-border-width: 4px !default;
$webchat-border-radius: 10px !default;
$webchat-width: 190px !default;

// Homepage
// -------------------------
$hero-gradient-first-color: #7085B6 !default;
$hero-gradient-second-color: #87A7D9 !default;
$hero-gradient-third-color: #DEF3F8 !default;
$hero-gradient-direction: -225deg !default;

$hero-button-group-button-active-text-color: $btn-primary-bg !default;
$hero-button-group-height: 4em !default;
$hero-button-group-btn-offset: 2px !default; // Amount of space between search form hero buttons
$hero-button-group-btn-icon-valign: baseline !default;
$hero-button-group-btn-display: inline-block !default;
$hero-button-min-width: 120px !default;
$hero-button-group-button-primary-color: transparent !default;
$hero-button-group-button-primary-text-color: $gray !default;
$hero-button-group-button-primary-text-hover-color: $hero-button-group-button-active-text-color !default;
$hero-button-group-container-border-bottom: 1px solid $base-border-color !default;
$hero-button-group-button-active-box-shadow: 0 2px 0 0 $brand-primary !default;

$hero-message-bg-color: $brand-primary !default;
$hero-message-bg-color_handheld: $hero-message-bg-color !default;
$hero-message-text-color: #fff !default;
$hero-message-text-color_handheld: #fff !default;
$hero-message-text-color-transparent_handheld: $brand-primary !default;
$hero-message-header-font-size: $font-size-h2 !default;
$hero-message-header-font-size_handheld: $font-size-h2_handheld !default;
$hero-message-search-form-header-font-size: $font-size-h2 !default;
$hero-message-background-opacity: .85 !default;
$hero-message-text-shadow: $shadow-text !default;
$hero-message-border-radius: 0 !default;
$hero-message-description-font-size: inherit !default;
$search-form-hero-height: initial !default;

$homepage-background-attachment: fixed !default;
$homepage-background-position: initial !default;

// Search Form
$search-form-container-background-color: #fff !default;
$search-form-container-background-opacity: 0.9 !default;
$search-form-traveler-label-width: 80px !default;
$search-form-child-ages-container-width: 90px !default;
$input-field-icon-color: $gray-light !default;
$search-form-container-box-shadow: $shadow-xl !default;
$search-form-outer-container-bg: #fff !default;
$search-form-outer-container-border-radius: 0 !default;
$search-form-section-padding: $padding-common-md !default;
$remove-btn-color: $brand-warning !default;
$remove-btn-hover-color: $brand-warning !default;

// Used in mixin: section-body-styled
$section-body-styled-padding: $padding-base-vertical * 4 !default;
$section-body-styled-bg-color: #fff !default;
$section-body-styled-main-bg-color: $almost-white !default;
$section-body-styled-border-radius: $product-body-border-radius !default;

// Manage Booking Pages
$manage-booking-nav-pills-padding: $padding-large-vertical 0 !default;
$manage-booking-main-bg-color: $almost-white !default;
$manage-booking-section-bg-color: $section-body-styled-bg-color !default;
$manage-booking-section-border-radius: $product-body-border-radius !default;

// Header dropdown menu
$loyalty-discount-dropdown-menu-has-caret: none !default;
$loyalty-discount-dropdown-menu-margin-top: 0 !default;
$loyalty-discount-dropdown-menu-link-color: $text-color !default;
$loyalty-discount-dropdown-menu-hover-color: $link-hover-color !default;

// APPLICATION ICONS
// -------------------------

$icon-font-size: 120% !default;
$icon-vertical-align: baseline !default; // applies to ALL icons, including client ones
$app-icon-font-family: "switchflyShopping" !default;
$app-icon-font-path: "//v251.static.core-test.switchfly.com/static/11483d3/resources/shopping/images/application/app-icons/fonts" !default;

$icon-clear: "\e959" !default;
$icon-meeting: "\e902" !default;
$icon-alert__help: "\e93f" !default;
$icon-alert__help--outline: "\e93e" !default;
$icon-alert__info: "\e940" !default;
$icon-alert__info--outline: "\e920" !default;
$icon-alert__warning: "\e93d" !default;
$icon-alert__warning--outline: "\e904" !default;
$icon-alert__pending: "\e975" !default;
$icon-closet: "\e909" !default;
$icon-galley: "\e95a" !default;
$icon-search-form__date: "\e90a" !default;
$icon-gym-fitness: "\e90b" !default;
$icon-product__activity: "\e90c" !default;
$icon-product__bundle: "\e91b" !default; // Note: this is double width
$icon-product__car: "\e958" !default;
$icon-product__flight: "\e91c" !default;
$icon-product__hotel: "\e957" !default;
$icon-product__cruise: "\e900" !default;
$icon-product__train: "\e901" !default;
$icon-product__selected: "\e942" !default;
$icon-product__keep: "\e943" !default;
$icon-product__remove: "\e94d" !default;
$icon-product__remove--outline: "\e94c" !default;
$icon-product__add: "\e94f" !default;
$icon-product__add--outline: "\e94e" !default;
$icon-activity--remove: "\e907" !default;
$icon-activity--add: "\e908" !default;
$icon-flight__landing: "\e93b" !default;
$icon-flight__take-off: "\e93c" !default;
$icon-flight__direction: "\e947" !default;
$icon-flight__duration: "\e948" !default;
$icon-car__num-pax: "\e903" !default;
$icon-car__unlimited-miles: "\e905" !default;
$icon-car__drive-type: "\e906" !default;
$icon-car__transmission: "\e90f" !default;
$icon-car__door-count: "\e910" !default;
$icon-car__fuel-type: "\e921" !default;
$icon-all-inclusive: "\e90d" !default;
$icon-user: "\e95b" !default;
$icon-restrooms: "\e90e" !default;
$icon-spa: "\e911" !default;
$icon-tennis: "\e912" !default;
$icon-ski-in-out: "\e913" !default;
$icon-restaurant: "\e914" !default;
$icon-child-services: "\e915" !default;
$icon-teen-programs: "\e916" !default;
$icon-casino: "\e917" !default;
$icon-airport-shuttle: "\e918" !default;
$icon-secure-connection: "\e919" !default;
$icon-undo: "\e91a" !default;
$icon-refresh-replay: "\e91e" !default;
$icon-filter: "\e91f" !default;
$icon-air-conditioning: "\e922" !default;
$icon-wifi: "\e923" !default;
$icon-smoke-free: "\e924" !default;
$icon-room-service: "\e925" !default;
$icon-pets: "\e926" !default;
$icon-pets--small: "\e927" !default;
$icon-internet-device-hub: "\e928" !default;
$icon-wheelchair-accessible: "\e929" !default;
$icon-golf: "\e92a" !default;
$icon-free-parking: "\e92b" !default;
$icon-continental-breakfast: "\e92c" !default;
$icon-babysitting-child-care: "\e92d" !default;
$icon-business-center: "\e92e" !default;
$icon-beach: "\e92f" !default;
$icon-pool: "\e930" !default;
$icon-pool--swimming: "\e931" !default;
$icon-bar--lounge: "\e932" !default;
$icon-bar--local: "\e933" !default;
$icon-seat-map__exit-left: "\e934" !default;
$icon-seat-map__exit-right: "\e935" !default;
$icon-seat-map__seat--selected: "\e95f" !default;
$icon-seat-map__stairs: "\e96d" !default;
$icon-scroll--prev-item: "\e936" !default;
$icon-scroll--next-item: "\e954" !default;
$icon-back-to-results: "\e937" !default;
$icon-booking-continue: "\e95c" !default;
$icon-list-item__special: "\e938" !default;
$icon-list-item__default-amenity: "\e939" !default;
$icon-list-item__prepaid: "\e93a" !default;
$icon-shopping-cart: "\e941" !default;
$icon-modal--close: "\e944" !default;
$icon-flight__stopover: "\e973" !default;
$icon-baggage-details: "\e945" !default;
$icon-num-bags: "\e946" !default;
$icon-input__search: "\e949" !default;
$icon-input__checkbox-toggle--selected: "\e95f" !default; // shared with icon-seat-map__seat--selected
$icon-input__select-indicator: "\e952" !default;
$icon-modify-search: "\e94a" !default;
$icon-rating__full: "\e94b" !default; // .rating is defined in app-global.base.scss
$icon-rating__half: "\e91d" !default; // .rating is defined in app-global.base.scss
$icon-menu: "\e950" !default;
$icon-collapsible--closed: "\e953" !default;
$icon-collapsible--opened: "\e951" !default;
$icon-nav__dropdown: "\e95d" !default;
$icon-product__location--origin: "\e955" !default;
$icon-product__location--destination: "\e956" !default;
$icon-product__location--airport: "\e91c" !default; //same as $icon-product__flight
$icon-search-form__location--origin: "\e955" !default;
$icon-search-form__location--destination: "\e956" !default;
$icon-product__deal: "\e95e" !default;
$icon-social__xing: "\e965" !default;
$icon-social__googleplus: "\e966" !default;
$icon-social__github: "\e960" !default;
$icon-social__flickr: "\e961" !default;
$icon-social__pintrest: "\e962" !default;
$icon-social__tumblr: "\e963" !default;
$icon-social__twitter: "\e964" !default;
$icon-social__facebook: "\e967" !default;
$icon-social__instagram: "\e968" !default;
$icon-social__linkedin: "\e969" !default;
$icon-social__vimeo: "\e96a" !default;
$icon-social__youtube: "\e96b" !default;
$icon-product__deal-loyalty: "\e96c" !default;
$icon-adults-only: "\e96e" !default;
$icon-open__external-link: "\e96f" !default;
$icon-map-marker: "\e970" !default;
$icon-product__lowest-price: "\e971" !default;
$icon-payment-options: "\e972" !default;
$icon-marcus-pay: "\e985" !default;
$icon-marcus-pay-light: url('#{$assetsPath}/checkout/payment_options_marcuspay_light.png') !default;
$icon-view-edit: "\e953" !default;
$icon-flight__featured: "\e974" !default;
$icon-branded-fares__available-for-charge: "\e976" !default;
$icon-branded-fares__included: "\e977" !default;
$icon-branded-fares__not_available: "\e978" !default;
$icon-branded-fares__baggage-included: "\e979" !default;
$icon-branded-fares__baggage-not-included: "\e97a" !default;
$icon-product-notice__enhanced-health-measures-badge: "\e97b" !default;
$icon-image-gallery: "\e97c" !default;
$icon-product-amenity__no: "\e97d" !default;
$icon-product-amenity__yes: "\e97e" !default;
$icon-product-amenity__fee: "\e97f" !default;
$icon-trip__booked: "\e943" !default;
$icon-trip__modified: "\e948" !default;
$icon-trip__cancelled: "\e944" !default;
$icon-trip__payment-due: "\e93d" !default;
$icon-trip__saved: "\e980" !default;
$icon-product__bundles-no-air: "\e981" !default;
$icon-chat-bubble: "\e982" !default;
$icon-price_drop: "\e98a" !default;
$icon-product__departure_point: "\e983" !default;
$icon-product__return_point: "\e984" !default;
$icon-product__language: "\e986" !default;
$icon-product__duration: "\e987" !default;
$icon-product__excluded: "\e988" !default;
$icon-product__included: "\e989" !default;
$icon-user__add: "\e98b" !default;
$icon-user__base: "\e95b" !default;

$product-group_0--feature-color: darken($brand-featured, 2%) !default;
$product-group_1--feature-color: $brand-primary !default;
$product-group_2--feature-color: $brand-info !default;
$product-group_3--feature-color: $gray !default;
$product-group_4--feature-color: $gray-dark !default;

$product-summary-active-background-color: $almost-white !default;
$product-summary-link-color: $link-color !default;
$product-summary-link-color-hover: $link-hover-color !default;
$product-summary-link-disabled-color: $btn-disabled-color !default;
$product-details-background-color: $product-summary-active-background-color !default;

$product-group-fare-cell-border-width: 1px !default;
$product-group-fare-cell-border-color: $gray-lighter !default;
$product-group-fare-cell-background-color: #fff !default;
$product-group-fare-cell-active-background-color: $almost-white !default;

$product-group-fare-cell-price-color: $brand-primary !default;
$product-group-fare-cell-active-price-color: $product-group-fare-cell-price-color !default;
$product-group-details-price-font-size: $product-price-font-size !default;

$icon-include-color: $text-color !default;
$icon-exclude-color: $text-color !default;

// SKELETON SCREEN SETTINGS
// -------------------------
$skeleton-screen-content-block-color: #E7E9EB !default;
$skeleton-screen-overlay-rbga-color: linear-gradient(to right, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6) 100%) !default;

// SUB-HEADER
// -------------------------
$sub-header-bg-color: transparent !default;

// BRANDED FARE
// -------------------------
$branded-fare-available-for-charge-icon-color: $gray !default;
$branded-fare-included-icon-color: $state-success-text !default;
$branded-fare-not-available-icon-color: $state-danger-text !default;


// BRANDED FARE UPSELL MODEL
// -------------------------
$upsell-modal-upsell-fare-short-description-color: $brand-success !default;

$upsell-modal-card-fare-name-color: $kard-title-text-color !default;
$upsell-modal-card-fare-name-size: $font-size-large !default;

$upsell-modal-selected-fare-short-description-color: $brand-warning !default;
$upsell-modal-selected-fare-card-background-color: $kard-body-bg-color !default;
$upsell-modal-selected-fare-card-background-color-opacity: 1 !default; // Must be between 0 and 1 inclusive
$upsell-modal-selected-fare-inclusion-list-icon-color: $brand-warning !default;

$upsell-modal-fare-card-border-width: 0 !default;
$upsell-modal-fare-card-border-color: $kard-body-bg-color !default;

$upsell-modal-upsell-fare-short-description-color: $brand-warning !default;
$upsell-modal-upsell-fare-inclusion-list-icon-color: $brand-success !default;
$upsell-modal-upsell-fare-card-background-color: $brand-primary !default;
$upsell-modal-upsell-fare-card-background-color-opacity: 0.1 !default; // Must be between 0 and 1 inclusive

$upsell-modal-price-color: $gray-dark !default;
$upsell-modal-price-size: $font-size-large !default;

$upsell-modal-fare-card-box-shadow: $shadow-md !default;

// STOPOVER
// --------------------------
$stopover-bg-color: $alert-success-bg !default;
$stopover-text-color: $gray-dark !default;
$box-border: 1px solid currentColor !default;
$box-border-radius: $border-radius-base !default;

// INSURANCE
// --------------------------
$checkout-insurance-section-image-max-width-handheld: 50% !default;
$checkout-insurance-section-body-background-color: $checkout-section-body-background-color !default;
$checkout-insurance-section-body-text-color: dynamicColor($checkout-section-body-background-color) !default;
$checkout-insurance-section-body-link-color: $link-color !default;

// Progress bar
// -------------------------
$progress-bar-width-desktop: 400px !default;
$progress-bar-width-handheld: 90vw !default;
$progress-bar-height-desktop: $padding-common-md !default;
$progress-bar-height-handheld: $padding-common-md !default;
$progress-bar-background-color: $gray-medium !default;
$progress-bar-foreground-color: $brand-featured !default;
$progress-bar-foreground-bars-color: darken($progress-bar-foreground-color, 15%) !default;
$progress-bar-border-radius: 0 !default;
$progress-bar-border-color: transparent !default;

// Primary Price Styling
// -------------------------
// Primary Price value
$product-primary-price-value-color: $product-price-total-color !default;
$product-primary-price-value-font-weight: $product-price-font-weight !default;
$product-primary-price-value-font-size: $product-price-font-size !default;
$product-primary-price-value-font-size_handheld: $product-price-font-size !default;

// Primary Price label
$product-primary-price-label-color: $product-price-total-color !default;
$product-primary-price-label-font-weight: 400 !default;
$product-primary-price-label-font-size: $font-size-base !default;
$product-primary-price-label-font-size_handheld: $font-size-small_handheld !default;

// Secondary price value & label
$product-secondary-price-color: $text-muted !default;
$product-secondary-price-font-weight: 400 !default;
$product-secondary-price-font-size: $font-size-base !default;
$product-secondary-price-font-size_handheld: $font-size-base_handheld !default;

// Baggage Info Section
$baggage-info-section-bg-color: $almost-white !default;
$baggage-info-included-icon-color: $branded-fare-included-icon-color !default;
$baggage-info-not-included-icon-color: $brand-warning !default;

// Brand foreground/text colors
// -------------------------
$brand-success-text-color: dynamicColor($brand-success) !default;
$brand-warning-text-color: dynamicColor($brand-warning) !default;
$brand-info-text-color: dynamicColor($brand-info) !default;
$brand-active-text-color: dynamicColor($brand-active) !default;
$brand-special-text-color: dynamicColor($brand-special) !default;
$brand-featured-text-color: dynamicColor($brand-featured) !default;
